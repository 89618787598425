import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {Input, Button, Form, Transition, Label, Icon, Container, Popup, Checkbox, Card, Message} from "semantic-ui-react";
import { findById, findByIdAndUpdate, findEnderecoByCep } from "../../../actions/condominio";
import * as FormValidator from "../../../utils/formValidator";
import Erro from '../../layouts/mensagens/Erro';
import MenuVertical from '../../layouts/MenuVertical';
import { Redirect, Link } from 'react-router-dom';
import InputMask from "react-text-mask";
import { formatDocumento } from '../../../utils/formatador';
import api from '../../../api';

class EditarCondominio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condominio: {
        nome: "",
        documento: "",
        usarFracaoIdeal: false,
        exibirDocsLinksVotacao: true,
        endereco: {
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          numero: "",
          uf: "",
          complemento: ""
        }
      },
      errors: {},
      sucesso: false,
      loading: true,
      ruaBairroManual: false,
      enderecoManual: false,
    };
  }

  componentDidMount = async () => {
    try {
      const condominio = await api.condominio.findForEdit(this.props.match.params.id);
      this.setState({ condominio });
    } catch (e) {
      this.setState({ errors: e.data.errors });
    }

    this.setState({
      loading: false,
      ruaBairroManual: false,
      enderecoManual: false,
    });

    if (this.state.condominio && this.state.condominio.endereco.cep) {
      const endereco = await this.props.findEnderecoByCep(this.state.condominio.endereco.cep);
      if (endereco) {
        this.setState({
          enderecoManual: false,
          ruaBairroManual: !endereco.logradouro || !endereco.bairro,
        });
      } else {
        this.setState({ enderecoManual: true });
      }
    }
  };

  onChangeDocumento = e => {
    this.setState({
      condominio: { ...this.state.condominio, [e.target.name]: e.target.value.replace(/\D/g, '') },
      errors: {}
    });
  }

  onChange = e => {
    this.setState({
      condominio: { ...this.state.condominio, [e.target.name]: e.target.value },
      errors: {}, sucesso: false
    });
  };

  onChangeEndereco = async e => {
    const ufSizeValid = e.target.name !== 'uf' || (e.target.name === 'uf' && e.target.value.length <= 2);
    const cepSizeValid = e.target.name !== 'cep' || (e.target.name === 'cep' && e.target.value.length <= 8);

    if (ufSizeValid && cepSizeValid) {
      this.setState({
        condominio: {
          ...this.state.condominio,
          endereco: {
            ...this.state.condominio.endereco,
            [e.target.name]: e.target.value
          }
        },
        errors: {},
        sucesso: false
      });

      if (e.target.name === "cep" && e.target.value && !isNaN(e.target.value) && e.target.value.length === 8) {
        const endereco = await this.props.findEnderecoByCep(e.target.value);
        if (endereco) {
          this.setState({
            condominio: {
              ...this.state.condominio,
              endereco: {
                ...this.state.condominio.endereco,
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                cidade: endereco.localidade,
                uf: endereco.uf,
              }
            },
            ruaBairroManual: !endereco.logradouro || !endereco.bairro,
          });
        } else {
          this.setState({
            condominio: {
              ...this.state.condominio,
              endereco: {
                ...this.state.condominio.endereco,
                logradouro: '',
                bairro: '',
                cidade: '',
                numero: '',
                uf: '',
                complemento: '',
              }
            },
            ruaBairroManual: false,
            enderecoManual: true,
          });
        }
      }
    }
  };

  onChangeUsarFracaoIdeal = (e, { name, checked }) => {
    this.setState({
      condominio: { ...this.state.condominio, [name]: checked },
      errors: {},
    });
  };

  onExibirDocsLinksVotacao = (e, { name, checked }) => {
    this.setState({
      condominio: { ...this.state.condominio, [name]: checked },
      errors: {},
    });
  }

  validate = (condominio) => {
    const errors = {};

    if (FormValidator.isVazio(condominio.nome)) {
      errors.nome = "Nome é obrigatório";
    }

    if (FormValidator.isVazio(condominio.documento)) {
      errors.documento = "Documento é obrigatório";
    } else if (!FormValidator.isCpf(condominio.documento) && !FormValidator.isCnpj(condominio.documento)) {
      errors.documento = 'Documento inválido';
    }

    if (!FormValidator.isVazio(condominio.endereco.cep) && !FormValidator.isCep(condominio.endereco.cep)) {
      errors.cep = "CEP inválido";
    }

    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.condominio);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(this.state.condominio._id, { ...this.state.condominio, unidades: [] });
      this.setState({
        loading: false,
        sucesso: (Object.keys(this.props.errors).length === 0),
        errors: this.props.errors
      })
    }
  };

  render() {
    const { condominio, errors, loading, sucesso, ruaBairroManual, enderecoManual } = this.state;
    return (
      <div>
        {!!errors.global && <Redirect to="/" />}
        <MenuHorizontal />
        <div className="corpo-dashboard">
          <MenuVertical condominio={condominio} />
          <Container className="ui-crud-container" fluid>
            <Form onSubmit={this.onSubmitEditarDados} loading={loading} autoComplete={"off"} size="large">
              <div className="ui-crud-form">
                <h3>Dados do Condomínio / Sociedade / Associação</h3>
                <Form.Group>
                  <Form.Field error={!!errors.nome} width={11}>
                    <label htmlFor="nome">Nome</label>
                    <Input
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      onChange={this.onChange}
                      value={condominio.nome}
                    />
                    <Erro text={errors.nome} />
                  </Form.Field>

                    <Form.Field error={!!errors.documento} width={5}>
                        <label htmlFor="documento">Documento</label>
                        <Input
                            type="text"
                            children={
                                <InputMask
                                    id="documento"
                                    name="documento"
                                    placeholder="CPF ou CNPJ"
                                    onInput={this.onChangeDocumento}
                                    value={formatDocumento(condominio.documento)}
                                    mask={val =>
                                        (val.replace(/\D/g, '').length <= 11 ?
                                                [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                                                             : [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                        )
                                    }
                                    guide={true}
                                />
                            }
                        />
                        <Erro text={errors.documento} />
                    </Form.Field>
                </Form.Group>

                  <Card fluid>
                      <Card.Content>
                          <Card.Description>
                              <strong>Endereço</strong>
                          </Card.Description>
                          <Form.Group widths={2} style={{ marginTop: 20 }}>
                              <Form.Field error={!!errors.cep}>
                                  <label htmlFor="cep">CEP</label>
                                  <Input
                                      name="cep"
                                      type="text"
                                      placeholder="CEP"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.cep}
                                  />
                                  <Erro text={errors.cep} />
                              </Form.Field>
                              <Form.Field error={!!errors.logradouro}>
                                  <label htmlFor="logradouro">Logradouro</label>
                                  <Input
                                      name="logradouro"
                                      type="text"
                                      placeholder="Logradouro"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.logradouro}
                                      readOnly={!ruaBairroManual && !enderecoManual}
                                  />
                                  <Erro text={errors.logradouro} />
                              </Form.Field>
                          </Form.Group>

                          <Form.Group widths={4}>
                              <Form.Field error={!!errors.bairro}>
                                  <label htmlFor="bairro">Bairro</label>
                                  <Input
                                      name="bairro"
                                      type="text"
                                      placeholder="Bairro"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.bairro}
                                      readOnly={!ruaBairroManual && !enderecoManual}
                                  />
                                  <Erro text={errors.bairro} />
                              </Form.Field>
                              <Form.Field error={!!errors.numero}>
                                  <label htmlFor="numero">Número</label>
                                  <Input
                                      name="numero"
                                      type="text"
                                      placeholder="Número"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.numero}
                                  />
                                  <Erro text={errors.numero} />
                              </Form.Field>
                              <Form.Field error={!!errors.cidade}>
                                  <label htmlFor="cidade">Cidade</label>
                                  <Input
                                      name="cidade"
                                      type="text"
                                      placeholder="Cidade"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.cidade}
                                      readOnly={!enderecoManual}
                                  />
                                  <Erro text={errors.cidade} />
                              </Form.Field>
                              <Form.Field error={!!errors.uf}>
                                  <label htmlFor="uf">UF</label>
                                  <Input
                                      name="uf"
                                      type="text"
                                      placeholder="UF"
                                      onChange={this.onChangeEndereco}
                                      value={condominio.endereco.uf}
                                      readOnly={!enderecoManual}
                                  />
                                  <Erro text={errors.uf} />
                              </Form.Field>
                          </Form.Group>
                      </Card.Content>
                  </Card>

                  <Card fluid>
                      <Card.Content>
                          <Card.Description>
                              <strong>Configurações Adicionais</strong>
                          </Card.Description>

                          <Form.Group style={{ marginTop: 20 }}>
                              <Form.Field width={5}>
                                  <label htmlFor="usarFracaoIdeal" style={styles.votacaoIniciadaLabel}>Desativar/Ativar Fração Ideal</label>
                                  <Popup
                                      trigger={
                                          <Checkbox
                                              toggle
                                              name="usarFracaoIdeal"
                                              onChange={this.onChangeUsarFracaoIdeal}
                                              checked={condominio.usarFracaoIdeal} style={{ marginTop: "10px" }}
                                          />
                                      }
                                      content='Clique aqui para desativar ou ativar a fração ideal'
                                      size='large'
                                      position='top center'
                                  />
                              </Form.Field>

                              <Form.Field width={11}>
                                  <label htmlFor="exibirDocsLinksVotacao" style={styles.votacaoIniciadaLabel}>Ocultar/Exibir Documentos e Links de Acesso</label>
                                  <Popup
                                      trigger={
                                          <Checkbox
                                              toggle
                                              name="exibirDocsLinksVotacao"
                                              onChange={this.onExibirDocsLinksVotacao}
                                              checked={condominio.exibirDocsLinksVotacao} style={{ marginTop: "10px" }}
                                          />
                                      }
                                      content='Clique aqui para ocultar ou exibir os documentos e links de acesso nos resultados das votações para os usuários.'
                                      size='large'
                                      position='top center'
                                  />
                              </Form.Field>
                          </Form.Group>

                          {condominio.votoSecreto && (
                              <Message
                                  style={{ marginTop: 20 }}
                                  size="small"
                                  color="primary">
                                  <Message.Header>Atenção</Message.Header>
                                  <p style={{ marginTop: 8 }}>Este Condomínio / Sociedade / Associação foi cadastrado com a opção de <strong>voto secreto</strong>.</p>
                              </Message>
                          )}
                      </Card.Content>
                  </Card>

                <div style={styles.divBtn}>
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="btn-ui-1"
                    size="large"
                  >
                    Editar Dados
                  </Button>
                </div>
                <Transition animation={"fade up"} duration={1000} visible={!!sucesso}>
                  <div style={{ textAlign: "center", paddingTop: "25px" }}>
                    <Label color="green">
                      <Icon name='check' /> Dados alterados com sucesso!
                    </Label>
                    <br /><br />
                    <Link to={`/condominios/${condominio._id}`}>Voltar</Link>
                  </div>
                </Transition>
              </div>
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}

const styles = {
  divBtn: {
    textAlign: "center",
    marginTop: "30px"
  }
};

const mapStateToProps = (state) => {
  return {
    condominio: state.condominio.condominio,
    errors: state.condominio.errors
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    findById,
    findByIdAndUpdate,
    findEnderecoByCep
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCondominio);
