import React, {useEffect, useState} from 'react';
import { Button, Divider, Form, Grid, Icon, Image, Input, Message, Popup, Segment, Statistic, Transition } from 'semantic-ui-react';
import Erro from '../../layouts/mensagens/Erro';
import { Pie } from 'react-chartjs-2';
import * as FormValidator from '../../../utils/formValidator';
import api from '../../../api';

const EditarVotacaoResultado = ({ visible, votacao, condominio, loadingVotacao, history }) => {
  const [errors, setErrors] = useState({});
  const [sucesso, setSucesso] = useState(false);
  const [loading, setLoading] = useState({ documento: false, perguntas: true });
  const [perguntas, setPerguntas] = useState([]);
  const [formState, setFormState] = useState(votacao);
  const [ata, setAta] = useState(null);

  useEffect(
    () => {
      if (votacao && votacao._id && visible) {
        setFormState(votacao);
        loadPerguntas();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [votacao, visible]
  );

  const loadPerguntas = async () => {
    try {
      setPerguntas(await api.pergunta.findForStats(condominio._id, votacao._id));
      setLoading(p => ({ ...p, perguntas: false }));
    } catch (e) {
      console.log(e);
      setErrors(e.data.errors);
    }
  };

  const closeMessage = () => setSucesso(false);

  const onChangeFile = e => {
    const file = e.target.files;
    if (file && file[0] && file[0].type === 'application/pdf') {
      if (FormValidator.sizeLimit(file[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
        setAta(file[0]);
        setErrors({});
      } else {
        setErrors({ documentoAta: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb` });
      }
    }
  };

  const onSubmitDocumento = async () => {
    if (ata) {
      setLoading(p => ({ ...p, documento: true }));

      const formData = new FormData();

      formData.append('field', 'documentoAta');
      formData.append('condominio', condominio._id);
      formData.append('arquivo', ata);

      try {
        const documentoAta = await api.votacao.atualizarDocumento(votacao._id, formData);
        setFormState(p => ({ ...p, documentoAta }));
        setLoading(p => ({ ...p, documento: false }));
        setSucesso(true);
        setAta(null);
      } catch (e) {
        setErrors(e.data.errors);
      }
    } else {
      setErrors({ documentoAta: 'Selecione um arquivo PDF' });
    }
  };

  const getDocLink = () =>
    !!formState.documentoAta && formState.documentoAta.url ? (
      <a href={formState.documentoAta.url} target="_blank" rel="noopener noreferrer">Ver documento atual</a>
    ) : (
      <>Nenhum documento anexado</>
    );

  const getDocLabel = () =>
    !!formState.documentoAta && formState.documentoAta.url ? 'Alterar documento atual' : 'Selecionar documento PDF';

  const goToChat = (_, { id }) => history.push(`/condominios/${condominio._id}/votacoes/chat/${id}`);

  const getRandomColor = () => {
    const r = () => Math.floor(Math.random() * 255);
    return `rgba(${r()}, ${r()}, ${r()}, 0.5)`;
  };

  return (
    <Transition
      animation="fade up"
      duration={{ show: 700, hide: 0 }}
      visible={visible}
      transitionOnMount={true}
    >
      <Segment>
        <Segment>
          <Grid doubling>
            <Grid.Column width={10}>
              <h3><strong>Ata Final</strong></h3>
              <Form.Field error={!!errors.documentoAta} inline>
                <div style={{ position: "relative" }}>
                  <Transition animation="fade up" duration={{ show: 1000, hide: 400 }} visible={sucesso}>
                    <Message positive size="tiny" onDismiss={closeMessage}>
                      <Icon name='check' /> Documento alterado com sucesso!
                    </Message>
                  </Transition>
                  <div style={{ display: 'inline-block' }}>
                    <Popup
                      trigger={
                        <label htmlFor="documentoAta" className="label-ui-arquivo">
                          <span>
                            {ata ? (
                              <><Icon name="file pdf outline"/> {ata.name}</>
                            ) : (
                              <><Icon name="paperclip"/> {getDocLabel()}</>
                            )}
                          </span>
                          <Input
                            type="file"
                            id="documentoAta"
                            name="documentoAta"
                            style={{ display: 'none' }}
                            onChange={onChangeFile}
                            accept="application/pdf"
                          />
                        </label>
                      }
                    >
                      <Popup.Header>Selecione um documento PDF</Popup.Header>
                      <Popup.Content>
                          Ao adicionar um documento ele ficará disponível no resultado da votação para todos os usuários.
                      </Popup.Content>
                    </Popup>
                    <br />
                    {getDocLink()}
                  </div>
                  <div className="div-ui-acao">
                    {!!ata && (
                      <Button
                        disabled={loading.documento || loadingVotacao}
                        loading={loading.documento}
                        size="small"
                        className="btn-ui-1"
                        onClick={onSubmitDocumento}
                      >
                        {!!formState.documentoAta && formState.documentoAta.url ? 'Confirmar Alteração' : 'Enviar Ata Final'}
                      </Button>
                    )}
                  </div>
                </div>
                <Erro text={errors.documentoAta} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign='middle'>
              <a
                href="https://uniproof.com.br/landing-votecondominio/"
                target="_blank"
                rel="noreferrer noopener"
                style={{ marginTop: 10 }}
              >
                Clique no logo abaixo para ver como enviar e registrar sua ata eletronicamente em cartório.
                <Image
                  src="/images/logo-uniproof.svg"
                  className="ui button btn-ui-1"
                  centered
                  style={{ marginTop: 10 }}
                />
              </a>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment loading={loading.perguntas}>
          {perguntas.length > 0 ? (
            <div>
              <div style={{ marginBottom: "0px" }}>
                <h3><strong>Resultados</strong></h3>
              </div>
              {perguntas.map(pergunta => (
                <Segment className="primary" key={pergunta._id} style={{ marginBottom: "10px", marginTop: "20px" }}>
                  <Grid doubling>
                    <Grid.Column width={14}>
                      <h3 style={{ fontWeight: 100 }}>{pergunta.titulo}</h3>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='right'>
                      <Button className="btn-ui-1" id={pergunta._id} onClick={goToChat}>
                        <Icon name='comments outline' /> Chat
                      </Button>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <h4 style={{ marginTop: "0px" }}>Respostas</h4>
                  {pergunta.respostas.map((resposta, i) => (
                    <Segment className={`ui-resultado-respostas ui-resultado-respostas-color-${i + 1}`} key={resposta._id}>
                      <Grid verticalAlign='middle' doubling stackable>
                        <Grid.Column width={13}>
                          <h3 style={{ fontWeight: 300, fontSize: "12pt" }}>{resposta.titulo}</h3>
                        </Grid.Column>
                        <Grid.Column width={3} className="text-center">
                          {pergunta.fracao ? (
                            <Statistic className="ui-resultado-respostas-item" size="mini">
                              <Statistic.Value>{resposta.porcentagem}%</Statistic.Value>
                              <Statistic.Label>
                                dos votos
                              </Statistic.Label>
                            </Statistic>
                          ) : (
                            <Statistic className="ui-resultado-respostas-item" size="mini">
                              <Statistic.Value>{resposta.votos.length}</Statistic.Value>
                              <Statistic.Label>
                                Voto{resposta.votos.length > 1 && 's'}
                              </Statistic.Label>
                            </Statistic>
                          )}
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  ))}
                  {pergunta.respostas.map(it => it.votos.length).reduce((acc, count) => acc+count, 0) > 0 && (
                    <Grid verticalAlign='middle'>
                      <Grid.Column>
                        <Pie
                          data={{
                            labels: pergunta.respostas.map(it => it.titulo),
                            datasets: [{
                              label: pergunta.titulo,
                              data: pergunta.respostas.map(it => (pergunta.fracao ? it.porcentagem : it.votos.length)),
                              backgroundColor: pergunta.respostas.map(it => getRandomColor()),
                              borderColor: pergunta.respostas.map(it => getRandomColor())
                            }]
                          }}
                          height={100}
                        />
                      </Grid.Column>
                    </Grid>
                  )}
                </Segment>
              ))}
            </div>
          ) : (
            <div style={styles.vazio}>
              <p>Nenhuma pergunta / pauta criada ainda</p>
            </div>
          )}
        </Segment>
      </Segment>
    </Transition>
  );
};

const styles = {
  vazio: {
    padding: "30px 10px 20px 10px",
    textAlign: "center",
    fontSize: "13pt",
    fontWeight: 100,
    opacity: .7
  },
};

export default EditarVotacaoResultado;
