import React, { useState } from 'react';
import { Transition, Segment, Grid, Popup, Checkbox } from "semantic-ui-react";
import BotaoRelatorio from './BotaoRelatorio';
import api from '../../../api';
import { base64toBlob } from '../../../utils/fileSaverHelper';
import { saveAs } from 'file-saver';

const PDF = 'application/pdf';
const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const EditarVotacaoRelatorios = ({ visible, votacao, condominio }) => {
  const [showDocumentPresenceList, setShowDocumentPresenceList] = useState(true);
  const [showVoteList, setShowVoteList] = useState(false);
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [showVoteListForResult, setShowVoteListForResult] = useState(false);

  const gerarRelatorioPresenca = async () => {
    const response = await api.votacao.gerarRelatorioPresenca(votacao._id, condominio, showDocumentPresenceList, showDeviceInfo, showEmail);
    saveAs(await base64toBlob(response.data, PDF), 'relatorio-lista-presenca.pdf');
  };

  const gerarRelatorioResultadoVotacao = async () => {
    const response = await api.votacao.gerarRelatorioResultadoVotacao(votacao._id, condominio, showVoteListForResult);
    saveAs(await base64toBlob(response.data, PDF), 'relatorio-resultado-votacao.pdf');
  };

  const gerarRelatorioChatVotacao = async () => {
    const response = await api.votacao.gerarRelatorioChatVotacao(votacao._id, condominio);
    saveAs(await base64toBlob(response.data, PDF), 'relatorio-chat-votacao.pdf');
  };

  const gerarRelatorioAtaVotacao = async () => {
    const response = await api.votacao.gerarRelatorioAtaVotacao(votacao._id, condominio, showVoteList);
    saveAs(await base64toBlob(response.data, DOCX), 'relatorio-ata-votacao.docx');
  };

  const onChangeShowDocumentPresenceList = (e, { checked }) => {
    setShowDocumentPresenceList(checked);
  };

  const onChangeShowVoteList = (e, { checked }) => {
    setShowVoteList(checked);
  };

  const onChangeShowShowDeviceInfo = (e, { checked }) => {
    setShowDeviceInfo(checked);
  };

  const onChangeShowEmail = (e, { checked }) => {
    setShowEmail(checked);
  };

  const onChangeShowVoteListForResult = (e, { checked }) => {
    setShowVoteListForResult(checked);
  };

  return (
    <Transition
      animation="fade up"
      duration={{ show: 700, hide: 0 }}
      visible={visible}
      transitionOnMount={true}
    >
      <Segment>
        <Grid stackable columns={2} doubling>
          <BotaoRelatorio icon="pencil" label="Lista de Presença" onClick={gerarRelatorioPresenca}>
            <div style={styles.manyOptionsContainer}>
              <div style={styles.activateContainer}>
                <label htmlFor="ativo">Imprimir CPF/CNPJ do Responsável</label>
                <Popup
                  trigger={
                    <Checkbox
                      toggle
                      name="ativo"
                      onChange={onChangeShowDocumentPresenceList}
                      checked={showDocumentPresenceList}
                      style={{marginTop: "10px"}}
                    />
                  }
                  content="Selecione essa opção para exibir o CPF/CNPJ do responsável na lista de presença."
                  size='large'
                  position='top center'
                />
              </div>

              <div style={styles.activateContainer}>
                <label htmlFor="chbShowDeviceInfo">Imprimir MAC Address e dispositivo do Responsável</label>
                <Popup
                    trigger={
                      <Checkbox
                          toggle
                          name="chbShowDeviceInfo"
                          onChange={onChangeShowShowDeviceInfo}
                          checked={showDeviceInfo}
                          style={{marginTop: "10px"}}
                      />
                    }
                    content="Selecione essa opção para exibir o MAC Address e dispositivo utilizado pelo responsável na lista de presença."
                    size='large'
                    position='top center'
                />
              </div>

              <div style={styles.activateContainer}>
                <label htmlFor="chbShowEmail">Imprimir E-mail do Responsável</label>
                <Popup
                    trigger={
                      <Checkbox
                          toggle
                          name="chbShowEmail"
                          onChange={onChangeShowEmail}
                          checked={showEmail}
                          style={{marginTop: "10px"}}
                      />
                    }
                    content="Selecione essa opção para exibir o E-mail do responsável na lista de presença."
                    size='large'
                    position='top center'
                />
              </div>

            </div>
          </BotaoRelatorio>

          <BotaoRelatorio icon="check square outline" label="Resultado da Votação" onClick={gerarRelatorioResultadoVotacao}>
            {!condominio.votoSecreto &&
             <div style={styles.activateContainer}>
               <label htmlFor="chbShowVoteList">Imprimir os votos dos participantes</label>
               <Popup
                   trigger={
                     <Checkbox
                         toggle
                         name="chbShowVoteListForResult"
                         onChange={onChangeShowVoteListForResult}
                         checked={showVoteListForResult}
                         style={{marginTop: "10px"}}
                     />
                   }
                   content="Selecione essa opção para exibir o detalhamento dos votos dos participantes."
                   size='large'
                   position='top center'
               />
             </div>
            }
          </BotaoRelatorio>

          <BotaoRelatorio icon="comments outline" label="Chat" onClick={gerarRelatorioChatVotacao}/>

          <BotaoRelatorio icon="file word" label="Ata" onClick={gerarRelatorioAtaVotacao}>
            {!condominio.votoSecreto &&
             <div style={styles.activateContainer}>
               <label htmlFor="chbShowVoteList">Imprimir os votos dos participantes</label>
               <Popup
                   trigger={
                     <Checkbox
                         toggle
                         name="chbShowVoteList"
                         onChange={onChangeShowVoteList}
                         checked={showVoteList}
                         style={{marginTop: "10px"}}
                     />
                   }
                   content="Selecione essa opção para exibir o detalhamento dos votos dos participantes."
                   size='large'
                   position='top center'
               />
             </div>
            }
          </BotaoRelatorio>
        </Grid>
      </Segment>
    </Transition>
  );
};

const styles = {
  activateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  },
  manyOptionsContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }
};

export default EditarVotacaoRelatorios;
