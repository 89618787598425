import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { scrollToPosition } from "../utils/links";
import UserRoute from "./routes/UserRoute";
import GuestRoute from "./routes/GuestRoute";
import Erro404 from "./views/errors/404";
import Login from "./views/auth/Login";
import CadastrarCondominio from "./views/condominio/CadastrarCondominio";
import EditarCondominio from "./views/condominio/EditarCondominio";
import Votacoes from "./views/votacao/Votacoes";
import CadastrarVotacao from "./views/votacao/CadastrarVotacao";
import QuestionChat from "./views/votacao/QuestionChat";
import Perfil from "./views/admin/Perfil";
import DashboardCondominio from "./views/condominio/DashboardCondominio";
import Pagamento from "./views/admin/Pagamento";
import Unidades from "./views/unidade/Unidades";
import CadastrarUnidade from "./views/unidade/CadastrarUnidade";
import Logout from "./views/auth/Logout";
import EditarUnidade from "./views/unidade/EditarUnidade";
import Responsaveis from "./views/responsavel/Responsaveis";
import CadastroSindico from "./views/auth/CadastroSindico";
import ConfirmacaoCadastro from "./views/auth/ConfirmacaoCadastro";
import ConfirmacaoTrocaEmail from "./views/auth/ConfirmacaoTrocaEmail";
import ReverterTrocaEmail from "./views/auth/ReverterTrocaEmail";
import RecuperacaoSenha from "./views/auth/RecuperacaoSenha";
import Ajuda from "./views/geral/Ajuda";
import FaleConosco from "./views/geral/FaleConosco";
import PoliticaPrivacidade from "./views/geral/PoliticaPrivacidade";
import Notificacoes from "./views/notificacao/Notificacoes";
import CadastrarNotificacao from "./views/notificacao/CadastrarNotificacao";
import EditarNotificacao from "./views/notificacao/EditarNotificacao";
import EditarResponsavel from "./views/responsavel/EditarResponsavel";
import Historico from "./views/historicoPagamento/Historico";
import ImportacaoCSV from "./views/importacao/ImportacaoCSV";
import PagamentoSucesso from "./views/admin/PagamentoSucesso";
import Condominios from "./views/condominio/Condominios";
import EditarVotacao from "./views/votacao/EditarVotacao";

const App = ({ location }) => {
  return (
    <div>
      {scrollToPosition(0)}
      <Switch>
        <GuestRoute
          location={location}
          path="/cadastro"
          exact
          component={CadastroSindico}
        />
        <Route
          location={location}
          path="/confirmar-cadastro/:token"
          exact
          component={ConfirmacaoCadastro}
        />
        <Route
          location={location}
          path="/confirmar-troca-email/:token"
          exact
          component={ConfirmacaoTrocaEmail}
        />
        <Route
          location={location}
          path="/reverter-troca-email/:token"
          exact
          component={ReverterTrocaEmail}
        />
        <GuestRoute
          location={location}
          path="/recuperar-senha/:token"
          exact
          component={RecuperacaoSenha}
        />
        <UserRoute location={location} path="/" exact component={Condominios} />
        <UserRoute
          location={location}
          path="/condominios"
          exact
          component={Condominios}
        />
        <UserRoute
          location={location}
          path="/condominios/cadastrar"
          exact
          component={CadastrarCondominio}
        />
        <UserRoute
          location={location}
          path="/condominios/editar/:id"
          exact
          component={EditarCondominio}
        />
        <UserRoute
          location={location}
          path="/condominios/:id"
          exact
          component={DashboardCondominio}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/unidades"
          exact
          component={Unidades}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/unidades/cadastrar"
          exact
          component={CadastrarUnidade}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/unidades/editar/:id"
          exact
          component={EditarUnidade}
        />

        <UserRoute
          location={location}
          path="/condominios/:id/responsaveis"
          exact
          component={Responsaveis}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/responsaveis/editar/:id"
          exact
          component={EditarResponsavel}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/votacoes"
          exact
          component={Votacoes}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/votacoes/cadastrar"
          exact
          component={CadastrarVotacao}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/votacoes/editar/:id"
          exact
          component={EditarVotacao}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/votacoes/editar/:id/:action"
          exact
          component={EditarVotacao}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/votacoes/chat/:idPergunta"
          exact
          component={QuestionChat}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/notificacoes"
          exact
          component={Notificacoes}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/notificacoes/cadastrar"
          exact
          component={CadastrarNotificacao}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/notificacoes/editar/:id"
          exact
          component={EditarNotificacao}
        />
        <UserRoute
          location={location}
          path="/condominios/:condominioId/importacao"
          exact
          component={ImportacaoCSV}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/comprar"
          exact
          component={Pagamento}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/comprar/sucesso"
          exact
          component={PagamentoSucesso}
        />
        <UserRoute
          location={location}
          path="/condominios/:id/historico"
          exact
          component={Historico}
        />
        <UserRoute
          location={location}
          path="/perfil"
          exact
          component={Perfil}
        />
        <GuestRoute location={location} path="/login" exact component={Login} />
        <Route
          location={location}
          path="/logout"
          exact
          component={Logout}
        />

        <Route location={location} path="/ajuda" exact component={Ajuda} />
        <Route
          location={location}
          path="/fale-conosco"
          exact
          component={FaleConosco}
        />
        <Route
          location={location}
          path="/politica-de-privacidade"
          exact
          component={PoliticaPrivacidade}
        />
        <Route
          location={location}
          path="/pagamento/sucesso"
          exact
          component={PagamentoSucesso}
        />
        <Route location={location} component={Erro404} />
      </Switch>
    </div>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default App;
