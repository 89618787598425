import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import FormRecuperacaoSenha from "../../forms/FormRecuperacaoSenha";
import { recuperarSenha, confirmarTokenRecuperacaoSenha } from "../../../actions/auth";
import { Card, Container } from "semantic-ui-react";

class RecuperacaoSenha extends Component {
  state = {
    pagina: true
  };

  submitLogin = data =>
    this.props.login(data.email, data.senha).then(() => this.props.history.push("/"));

  submitEnviarEmailRecuperacaoSenha = data =>
    this.props.enviarEmailRecuperacaoSenha(data.email);

  submitRecuperarSenha = data =>
    this.props.recuperarSenha(data)
      .then(usuario => usuario);

  confirmarTokenRecuperacaoSenha = token =>
    this.props.confirmarTokenRecuperacaoSenha(token)
      .then(usuario => usuario);

  render() {
    return (
      <div>
        <MenuHorizontal />
        <div className="ui-pagina-login">
          <Container>
            <Card fluid style={styles.card} className="card-auth">
              <Card.Content>
                <Card.Header style={styles.header}>
                  <img src="/images/logo-voteassembleia-com-escrita.png" alt="Vote Assembleia logo" height="64" style={{ marginBottom: "15px" }} />
                  <p className="ui-title">Recuperação de senha</p>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <FormRecuperacaoSenha submit={this.submitRecuperarSenha} confirmarToken={this.confirmarTokenRecuperacaoSenha} token={this.props.match.params.token} />
              </Card.Content>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

RecuperacaoSenha.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tipoUsuario: PropTypes.string
    }).isRequired
  }).isRequired,
  recuperarSenha: PropTypes.func.isRequired,
  confirmarTokenRecuperacaoSenha: PropTypes.func.isRequired
};

const styles = {
  header: {
    padding: "10px 10px",
    textAlign: "center"
  },
  card: {
    padding: "10px 15px 0px 15px",
    margin: "auto",
    marginBottom: "40px",
    overflow: "hidden",
    maxWidth: "400px"
  },
  cardLink: {
    fontSize: "13px"
  }
}

export default connect(null, { recuperarSenha, confirmarTokenRecuperacaoSenha })(RecuperacaoSenha);
