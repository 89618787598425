import React from "react";
import { Link } from "react-router-dom";
import { Icon, Button, Container } from "semantic-ui-react";

const Erro404 = () => (
  <div className="pagina-404">
    <Container>
      <div className="mensagem-404">
        <img src="/images/logo-voteassembleia-com-escrita.png" alt="Vote Assembleia" className="logo-404" />
        <h3>Página não encontrada</h3>
        <p>O link que você tentou acessar pode estar quebrado ou a página pode ter sido removida.</p>

        <Link to="/">
          <Button className="btn-ui-1" icon labelPosition='right' size="large">
            Voltar Para página inicial <Icon name='right chevron' />
          </Button>
        </Link>
      </div>
    </Container>
  </div >
);

export default Erro404;
