import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Transition, Card, Button, Image } from 'semantic-ui-react';
import 'moment/locale/pt-br';
import { getMesAno } from '../../../utils/formatador';
moment.locale('pt-BR');

const Condominio = ({ condominio, usuario, className, readOnly, animation }) => {
  return (
    <Transition duration={!isNaN(animation) ? animation : 500} animation="fade up" transitionOnMount={true}>
      <Card style={{ width: "100%" }}>
        <Image src="/images/illustrations/unidades.svg" wrapped ui={false} />
        <Card.Content>
          <Card.Header style={{ wordWrap: "break-word" }}>{condominio.nome}</Card.Header>
          <Card.Meta>
            <h4 style={{ margin: 0, marginTop: 10 }}>Criado {moment(condominio.createdAt).fromNow()}</h4>
            {usuario && usuario.tipo === 'demo' ? (
              <h4 style={{ margin: 0, color: '#e83c6e' }}>Demonstração</h4>
            ) : (
              condominio.dataPagamento && condominio.dataVencimento ? (
                <h4 style={{ margin: 0 }}>Válido até {getMesAno(condominio.dataVencimento)}</h4>
              ) : (
                <h4 style={{ margin: 0, color: '#f00' }}>Ainda não ativado</h4>
              )
            )}
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to={`/condominios/${condominio._id}`} className="btn-ui-1" fluid size="large">Gerenciar Condomínio / Sociedade / Associação</Button>
        </Card.Content>
      </Card>
    </Transition>
  )
}

export default connect(null, {})(Condominio);
