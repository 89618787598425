import React from 'react';
import {Transition, Icon, Card, Button} from 'semantic-ui-react';
import { formatDecimal } from '../../../utils/formatador';
import moment from 'moment';

const tipoPagamento = {
  ATIVACAO: 'Ativação',
  RENOVACAO: 'Renovação',
  ADD_UNIDADE: 'Adição de unidades votantes'
};

const statusPagamento = {
  OP: {
    descricao: "Pedido Criado",
    icon: "clock outline"
  },
  CO: {
    descricao: "Pagamento Confirmado",
    icon: "check"
  },
  PE: {
    descricao: "Pagamento Pendente",
    icon: "clock outline"
  },
  CA: {
    descricao: "Pagamento Cancelado",
    icon: "ban"
  },
};

const ItemHistorico = ({ pagamento, consultarStatus }) => {
  return (
      <Transition duration="500" animation="fade up" transitionOnMount={true}>
        <Card color="violet" style={{ width: "100%" }}>
          <Card.Content style={{ padding: "20px" }}>
            {pagamento.status === 'PE' && pagamento.origem === 'NITRUS' && (
                <Button floated="right" icon className="btn-ui-1" onClick={consultarStatus}>
                  <Icon name='refresh' />
                </Button>
            )}
          <Card.Header>
            <h3>{tipoPagamento[pagamento.tipo]}</h3>
          </Card.Header>
          <Card.Description>
            <p style={{ fontSize: "1.1em" }}>
              <Icon name={statusPagamento[pagamento.status].icon} style={{ color: '#e83c6e' }} /> {statusPagamento[pagamento.status].descricao}
            </p>
            <p style={{ fontSize: "1.1em" }}>
            {pagamento.quantidadeUnidades} unidade{pagamento.quantidadeUnidades !== 1 && 's'} &nbsp;&bull;&nbsp; R$ {formatDecimal(pagamento.valor)}
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra style={{ padding: "20px" }}>
          <Card.Meta>
            <span className='date'>Criado {moment(pagamento.dataAbertura).format('llll')}</span>
          </Card.Meta>
        </Card.Content>
      </Card>
    </Transition>
  );
};

export default ItemHistorico;
