import React, { useState } from 'react';
import { Segment, Grid, Popup, Button, Icon, Form, Input } from 'semantic-ui-react';

const Resposta = ({ pergunta, resposta, submitEditar, submitExcluir, podeEditar }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [formState, setFormState] = useState({ pergunta: pergunta._id, ...resposta });

  const changeFormState = (name, value) => setFormState(p => ({ ...p, [name]: value }));
  const onChange = ({ target: { name, value } }) => changeFormState(name, value);

  const editar = () => setIsEdit(true);
  const salvar = () => submitEditar(formState);
  const excluir = () => submitExcluir(formState);

  const getContent = () => (
    <Segment style={{ marginBottom: "10px" }}>
      <Grid verticalAlign="middle" stackable>
        <Grid.Column width={podeEditar ? 12 : 16}>
          <p>{formState.titulo}</p>
        </Grid.Column>
        {podeEditar && (
          <Grid.Column width={4} className="action-ui-manter">
            <Popup
              content="Editar Resposta"
              position="top center"
              size="small"
              trigger={
                <Button
                  as="span"
                  icon
                  className="btn-ui-1"
                  size="tiny"
                  onClick={editar}
                >
                  <Icon name='pencil' />
                </Button>
              }
            />
            &nbsp;&nbsp;
            <Popup
              content="Excluir Resposta"
              position="top center"
              size="small"
              trigger={
                <Button
                  className="primary"
                  as="span"
                  icon
                  basic
                  size="tiny"
                  onClick={excluir}
                >
                  <Icon name='trash alternate' />
                </Button>
              }
            />
          </Grid.Column>
        )}
      </Grid>
    </Segment>
  );

  const getEdit = () => (
    <Form autoComplete="off" onSubmit={salvar}>
      <Form.Field style={{ marginBottom: "10px" }}>
        <Input
          type="text"
          name="titulo"
          placeholder="Digite uma possível resposta"
          value={formState.titulo}
          onChange={onChange}
          action={{
            content: 'Salvar',
            onClick: salvar,
            color: 'green',
            as: 'div',
            size: 'small'
          }}
          size="small"
          autoFocus
        />
      </Form.Field>
    </Form>
  );

  return isEdit ? getEdit() : getContent();
};

export default Resposta;
