import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import {
  Icon,
  Form,
  Radio,
  Input,
  Button,
  Message,
  Transition,
  Container,
} from 'semantic-ui-react';
import { create } from '../../../actions/unidade';
import { findByDocumento as findResponsavelByDocumento } from '../../../actions/responsavel';
import * as FormValidator from '../../../utils/formValidator';
import * as Formatador from '../../../utils/formatador';
import Erro from '../../layouts/mensagens/Erro';
import InputMask from 'react-text-mask';
import api from '../../../api';

class CadastrarUnidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      habilitado: true,
      condominio: {},
      unidade: {
        bloco: "",
        numero: "",
        descricao: "",
        condominio: "",
        ativo: true,
        bloqueadoVotacao: false,
        fracao: 1,
        responsavel: {
          documento: "",
          nome: "",
          email: "",
          celular: "",
        },
      },
      errors: {},
      errorsResponsavel: {},
      sucesso: false,
      loading: false,
      loadingResponsavel: false,
      responsavelExists: false,
    };
    this.pararBusca = this.pararBusca.bind(this);
  }

  componentDidMount = async () => {
    try {
      const condominio = await api.condominio.findSimpleById(this.props.match.params.id);
      const habilidado = await api.unidade.validarQuantidade(condominio._id).then(it => it.habilitado);

      this.setState({
        habilidado,
        condominio,
        loading: false,
        unidade: {
          ...this.state.unidade,
          condominio: condominio._id,
        },
      });
    } catch (e) {
      this.setState({
        loading: false,
        errors: e.data.errors,
      });
    }
  };

  onChange = (e) => {
    this.setState({
      unidade: { ...this.state.unidade, [e.target.name]: e.target.value },
      errors: {},
    });
  };

  onChangeTokenEmail = () => {
    this.setState({
      unidade: {
        ...this.state.unidade,
        enviarTokenEmail: !this.state.unidade.enviarTokenEmail,
      },
      errors: {},
    });
  };

  onChangeResponsavel = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'documento') {
      const documento =
        Formatador.removerChars(value).toString().length > 14
          ? Formatador.removerChars(value)
              .toString()
              .slice(0, 14)
          : Formatador.removerChars(value).toString();

      const tipoDocumento = documento.length > 11 ? 'CNPJ' : 'CPF';

      this.setState({
        unidade: {
          ...this.state.unidade,
          responsavel: {
            ...this.state.unidade.responsavel,
            nome: '',
            documento,
            tipoDocumento,
          },
        },
        errors: {},
        errorsResponsavel: {},
      });

      this.findResponsavelByDocumento(documento);
    } else {
      this.setState({
        unidade: {
          ...this.state.unidade,
          responsavel: {
            ...this.state.unidade.responsavel,
            [name]: value,
          },
        },
        errors: {},
        errorsResponsavel: {},
      });
    }
  };

  pararBusca() {
    clearTimeout(this.state.busca);
  }

  findResponsavelByDocumento = (documento) => {
    const buscar = async () => {
      await this.props.findResponsavelByDocumento(documento);
      this.setState({
        unidade: {
          ...this.state.unidade,
          responsavel: FormValidator.objIsEmpty(this.props.errors)
            ? {
                ...this.state.unidade.responsavel,
                nome: this.props.responsavel.nome,
                documento,
              }
            : { ...this.props.responsavel, documento },
        },
        errorsResponsavel: this.props.errorsResponsavel,
        loadingResponsavel: false,
        responsavelExists: FormValidator.objIsEmpty(
          this.props.errorsResponsavel
        ),
        errors: this.props.errors,
      });
    };
    const responsavel = this.state.unidade.responsavel;
    if (documento.length === 11 && !FormValidator.isCpf(documento))
      this.setState({ errorsResponsavel: { documento: 'CPF inválido' } });

    if (documento !== responsavel.documento) {
      this.pararBusca();
      if (FormValidator.isCpf(documento) || FormValidator.isCnpj(documento)) {
        this.setState({ loadingResponsavel: true, responsavelExists: false });
        this.setState({
          busca: setTimeout(function() {
            buscar();
          }, 1500),
        });
      } else {
        this.setState({
          loadingResponsavel: false,
          responsavelExists:
            FormValidator.isCpf(documento) || FormValidator.isCnpj(documento),
        });
      }
    }
  };

  validate = (unidade) => {
    const errors = {};
    const { condominio } = this.state;

    if (FormValidator.isVazio(unidade.numero)) {
      errors.numero = 'Número é obrigatório';
    }

    if (condominio && condominio.usarFracaoIdeal && !unidade.fracao) {
      errors.fracao = 'Fração é obrigatório';
    }

    if (FormValidator.isVazio(unidade.responsavel.documento.replace(/\D/g, ''))) {
      errors.documento = 'Responsável obrigatório';
    } else if (!FormValidator.isCpf(unidade.responsavel.documento) &&
               !FormValidator.isCnpj(unidade.responsavel.documento)) {
      errors.documento = 'Documento inválido';
    }

    if (!this.state.responsavelExists) {
      if (FormValidator.isVazio(unidade.responsavel.nome) ||
          !FormValidator.isNomeCompleto(unidade.responsavel.nome)) {
        errors.nome = 'Digite o nome completo';
      }

      if (!FormValidator.isVazio(unidade.responsavel.email) &&
          !FormValidator.isEmail(unidade.responsavel.email)) {
        errors.email = 'Insira um email válido';
      }

      if (!FormValidator.isVazio(unidade.responsavel.celular.replace(/\D/g, '')) &&
          !FormValidator.isNumeroCelular(unidade.responsavel.celular)) {
        errors.celular = 'Celular inválido';
      }
    }

    return errors;
  };

  onSubmitCadastrar = async (e) => {
    e.preventDefault();

    const errors = this.validate(this.state.unidade);
    this.setState({ errors });

    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });

      try {
        await api.unidade.create(this.state.unidade);
        this.setState({ loading: false, sucesso: true, errors: {} });
      } catch (e) {
        this.setState({ loading: false, sucesso: false, errors: e.data.errors });
      }
    }
  };

  disabledFields = () => {
    return (
      this.state.loadingResponsavel ||
      this.state.responsavelExists ||
      !this.state.unidade.responsavel.documento ||
      (!FormValidator.isCpf(this.state.unidade.responsavel.documento) &&
        this.state.unidade.responsavel.tipoDocumento === 'CPF') ||
      (!FormValidator.isCnpj(this.state.unidade.responsavel.documento) &&
        this.state.unidade.responsavel.tipoDocumento === 'CNPJ')
    );
  };

  deveRedirecionar = () => {
    let result = false;
    if (Object.keys(this.state.condominio).length > 0) {
      if (this.props.usuario.tipo === 'demo') {
        result = !this.state.habilitado;
      } else {
        result = this.state.condominio.totalUnidades === this.state.condominio.countUnidades;
      }
    }
    return result;
  };

  render() {
    const {
      unidade,
      errors,
      errorsResponsavel,
      loading,
      loadingResponsavel,
      sucesso,
      responsavelExists,
      condominio,
    } = this.state;
    return (
      <div>
        {this.deveRedirecionar() && this.props.history.goBack()}
        {!!errors.global && <Redirect to='/' />}
        <MenuHorizontal />
        <div className='corpo-dashboard'>
          <MenuVertical condominio={condominio} loading={loading} />
          <Container style={{ padding: '20px' }} fluid>
            {<Erro text={errors.error} tipo='block' />}
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation='fade up'
              transitionOnMount={true}
              visible={sucesso}
            >
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              >
                <Icon
                  name='check circle outline'
                  size='massive'
                  color='green'
                />
                <br />
                <h3>Unidade votante cadastrada com sucesso</h3>
                <Link
                  to={`/condominios/${condominio._id}/unidades`}
                  size='small'
                >
                  Voltar
                </Link>
              </div>
            </Transition>
            {!sucesso && (
              <Form
                onSubmit={this.onSubmitCadastrar}
                loading={loading}
                autoComplete={'off'}
                size='large'
              >
                <div className='ui-crud-form'>
                  <h3>Cadastro de Unidade Votante</h3>
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.bloco} width={3}>
                      <label htmlFor='bloco'>Bloco/Grupo</label>
                      <Input
                        name='bloco'
                        type='text'
                        placeholder='Bloco/Grupo'
                        onChange={this.onChange}
                        value={unidade.bloco}
                      />
                      <Erro text={errors.bloco} />
                    </Form.Field>
                    <Form.Field error={!!errors.numero} width={3}>
                      <label htmlFor='numero'>Número/Identificador</label>
                      <Input
                        name='numero'
                        type='text'
                        placeholder='Número'
                        onChange={this.onChange}
                        value={unidade.numero}
                      />
                      <Erro text={errors.numero} />
                    </Form.Field>
                    {condominio.usarFracaoIdeal && (
                      <Form.Field error={!!errors.fracao} width={3}>
                        <label htmlFor='numero'>Fração Ideal</label>
                        <Input
                          name='fracao'
                          type='number'
                          min={0.0000000001}
                          step={0.0000000001}
                          max={1}
                          placeholder='Fração Ideal'
                          onChange={this.onChange}
                          value={unidade.fracao}
                        />
                        <Erro text={errors.fracao} />
                      </Form.Field>
                    )}
                    <Form.Field
                      error={!!errors.enviarTokenEmail}
                      inline
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Radio
                        toggle
                        label='Enviar Token para essa unidade votante ao final do cadastramento'
                        name='enviarTokenEmail'
                        checked={unidade.enviarTokenEmail}
                        onChange={this.onChangeTokenEmail}
                      />
                      <Erro text={errors.enviarTokenEmail} />
                    </Form.Field>
                  </Form.Group>
                  <h3 style={{ marginTop: '40px' }}>Dados do Proprietário/Associado</h3>
                  <Form.Field error={!!errors.nome} width={16}>
                    {!!errorsResponsavel.responsavel && (
                      <Message visible size='small'>
                        <Message.Content>
                          Não encontramos um proprietário com esse número de
                          documento. Complete os dados adicionais abaixo.
                        </Message.Content>
                      </Message>
                    )}
                    {!!loadingResponsavel && (
                      <Message size='small'>
                        <Message.Content>
                          <Icon name='spinner' loading /> Buscando
                          proprietário...
                        </Message.Content>
                      </Message>
                    )}
                    {!!responsavelExists && (
                      <Message size='small' className='primary'>
                        <Message.Content>
                          <Icon name='check' /> Proprietário encontrado
                        </Message.Content>
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Group widths={2}>
                    <Form.Field
                      error={
                        !!errorsResponsavel.documento || !!errors.documento
                      }
                      width={6}
                    >
                      <label htmlFor='documento'>
                        Documento do Proprietário/Associado ou Procurador
                      </label>
                      <Input
                        type='text'
                        value={unidade.responsavel.documento}
                        focus={true}
                        children={
                          <InputMask
                            id='documento'
                            name='documento'
                            placeholder='CPF ou CNPJ'
                            onInput={this.onChangeResponsavel}
                            mask={
                              unidade.responsavel.tipoDocumento === 'CPF'
                                ? [
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                  ]
                                : [
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '/',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                  ]
                            }
                            guide={true}
                          />
                        }
                      />
                      <Erro
                        text={errorsResponsavel.documento || errors.documento}
                      />
                    </Form.Field>
                    <Form.Field error={!!errors.nome} width={10}>
                      <label htmlFor='nome'>Nome Completo</label>
                      <Input
                        name='nome'
                        type='text'
                        placeholder='Nome'
                        onChange={this.onChangeResponsavel}
                        value={unidade.responsavel.nome}
                        loading={loadingResponsavel}
                        disabled={this.disabledFields()}
                      />
                      <Erro text={errors.nome} />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Transition
                      duration={{ show: 400, hide: 250 }}
                      animation='fade up'
                      visible={!responsavelExists}
                    >
                      <Form.Field error={!!errors.celular} width={6}>
                        <label htmlFor='celular'>Celular</label>
                        <InputMask
                          type='text'
                          id='celular'
                          name='celular'
                          placeholder='(ddd) número'
                          mask={[
                            '(',
                            /\d/,
                            /\d/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide={true}
                          onChange={this.onChangeResponsavel}
                          value={unidade.responsavel.celular}
                          disabled={this.disabledFields()}
                        />
                        <Erro text={errors.celular} />
                      </Form.Field>
                    </Transition>
                    <Transition
                      duration={{ show: 400, hide: 250 }}
                      animation='fade up'
                      visible={!responsavelExists}
                    >
                      <Form.Field error={!!errors.email} width={10}>
                        <label htmlFor='email'>Email</label>
                        <Input
                          name='email'
                          type='text'
                          placeholder='Email'
                          onChange={this.onChangeResponsavel}
                          value={unidade.responsavel.email}
                          disabled={this.disabledFields()}
                        />
                        <Erro text={errors.email} />
                      </Form.Field>
                    </Transition>
                  </Form.Group>
                  <div style={styles.divBtn}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      className='btn-ui-1'
                      size='large'
                    >
                      Cadastrar Unidade Votante
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

const styles = {
  divBtn: {
    textAlign: 'center',
    marginTop: '20px',
  },
};

const mapStateToProps = (state) => {
  return {
    unidade: state.unidade.unidade,
    unidades: state.unidade.unidades,
    responsavel: state.responsavel.responsavel,
    tiposDocumentos: state.responsavel.tiposDocumentos,
    usuario: state.user.user,
    errors: state.unidade.errors,
    errorsResponsavel: state.responsavel.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create,
      findResponsavelByDocumento,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastrarUnidade);
