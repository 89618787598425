import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Form, TextArea, Input, Message, Icon, Transition, Button, Segment } from 'semantic-ui-react';
import Erro from "../layouts/mensagens/Erro";
import Validator from "validator";
import * as FormValidator from "../../utils/formValidator";
import InputMask from 'react-text-mask';
import { enviarEmailContato } from "../../actions/sistema";

class FormContato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: {
        nome: "",
        email: "",
        telefone: "",
        mensagem: ""
      },
      sucesso: false,
      loading: false,
      errors: {}
    }
  }

  onChange = e =>
    this.setState({
      dados: { ...this.state.dados, [e.target.name]: e.target.value },
      errors: {}
    });

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.dados);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.enviarEmailContato(this.state.dados);
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors
      });
    }
  };

  validate = dados => {
    const errors = {};
    if (FormValidator.isVazio(dados.nome)) errors.nome = "Nome é obrigatório";
    if (!Validator.isEmail(dados.email)) errors.email = "E-mail inválido";
    if (dados.telefone && !FormValidator.isNumeroCelular(dados.telefone)) errors.telefone = "Número inválido";
    if (FormValidator.isVazio(dados.mensagem) || dados.mensagem.length < 10 || dados.mensagem.length > 500) errors.mensagem = "Sua mensagem precisa ter de 10 a 500 caracteres";
    return errors;
  };

  render() {
    const { dados, sucesso, loading, errors } = this.state;
    return (
      <div>
        <Transition visible={sucesso} animation='fade down' duration={1200} transitionOnMount={true}>
          <Message floating className="mensagem-sucesso-email primary">
            <Icon name="check" size="big" />
            <br />
            <h3>E-mail enviado com sucesso!</h3>
            <br />
            <Button as={Link} to="/" className="btn-ui-1" size="large" >Voltar para tela inicial</Button>
          </Message>
        </Transition>

        <Transition visible={!sucesso} animation='fade up' duration={{ show: 1000, hide: 0 }} transitionOnMount={true}>
          <Segment style={styles.segment} className="ui-faleconosco-segment" raised>
            <Form onSubmit={this.handleSubmit} size="large">
              <Form.Field error={!!errors.nome}>
                <label htmlFor="nome">Nome</label>
                <Input
                  type="text"
                  name="nome"
                  placeholder='Nome'
                  value={dados.nome}
                  onChange={this.onChange}
                />
                <Erro text={errors.nome} />
              </Form.Field>
              <Form.Field error={!!errors.email}>
                <label htmlFor="email">E-mail</label>
                <Input
                  type="email"
                  name="email"
                  placeholder='Email'
                  value={dados.email}
                  onChange={this.onChange}
                />
                <Erro text={errors.email} />
              </Form.Field>
              <Form.Field error={!!errors.telefone}>
                <label htmlFor="Celular">Celular</label>
                <InputMask
                  type="text"
                  id="telefone"
                  name="telefone"
                  placeholder="(ddd) número"
                  mask={['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  guide={true}
                  value={dados.telefone}
                  onChange={this.onChange}
                />
                <Erro text={errors.telefone} />
              </Form.Field>
              <Form.Field error={!!errors.mensagem}>
                <label htmlFor="mensagem">Mensagem</label>
                <TextArea
                  name="mensagem"
                  placeholder="Mensagem"
                  rows={5}
                  value={dados.mensagem}
                  onChange={this.onChange}
                  style={{ minHeight: 150, maxHeight: 250 }}
                />
                <Erro text={errors.mensagem} />
              </Form.Field>
              <Button className="btn-ui-1" fluid loading={loading} disabled={loading} size="large" >Enviar</Button>

              {!!errors.global &&
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Erro text={errors.global} style={{ fontSize: "1.1em" }} />
                </div>
              }
            </Form>
          </Segment>
        </Transition>
      </div>
    )
  }
}

const styles = {
  segment: {
    padding: "50px 50px 40px 50px",
    margin: "auto",
    maxWidth: "480px"
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.sistema.errors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    enviarEmailContato,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContato);
