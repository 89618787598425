import React from 'react';
import { Transition, List, Responsive, Button, Icon, Modal, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import api from '../../../api';
import { base64toBlob } from '../../../utils/fileSaverHelper';
import { saveAs } from 'file-saver';

import './Notificacao.css';

const tipoNotificacao = {
  DEFAULT: 'Padrão',
  VOTING_CREATED: 'Votação Criada',
  VOTING_CLOSED: 'Votação Finalizada',
  CONVOCATION: 'Convocação'
};

const Notificacao = ({ notificacao, animation, sendAction, removeAction }) => {
  const getEnviarAction = (mobile = false) => (
    <Modal
      trigger={<Button positive size="large">{mobile ? <Icon name="send" /> : 'Enviar'}</Button>}
      header='Confirmação'
      content={`Deseja mesmo enviar esta notificação para as unidades votantes?`}
      size="mini"
      actions={[
        { key: 'close', content: 'Não', className: 'btn-ui-1 btn-ui-1-basic' },
        { key: 'done', content: 'Sim', className: 'btn-ui-1', positive: true, onClick: () => sendAction(notificacao._id) }
      ]}
    />
  );

  const getRemoveAction = (mobile = false) => (
    <Modal
      trigger={<Button className="btn-ui-1 btn-ui-1-basic" size="large">{mobile ? <Icon name="times" /> : 'Remover'}</Button>}
      header='Confirmação'
      content={`Deseja mesmo remover esta notificação?`}
      size="mini"
      actions={[
        { key: 'close', content: 'Não', className: 'btn-ui-1 btn-ui-1-basic' },
        { key: 'done', content: 'Sim', className: 'btn-ui-1', positive: true, onClick: () => removeAction(notificacao._id) }
      ]}
    />
  );

  const onClickRel = async () => {
    const rel = await api.notificacao.relatorio(notificacao.condominio, notificacao._id);
    saveAs(await base64toBlob(rel, 'application/pdf'), 'relatorio-notificacao.pdf');
  };

  return (
    <Transition duration={!isNaN(animation) ? animation : 500} animation="fade up" transitionOnMount={true}>
      <List.Item className="animated-list-item">
        <List.Content>
          <Responsive minWidth={768}>
            <List.Header className="notification-header">
              {tipoNotificacao[notificacao.tipo]}
              {notificacao.mensagem && (
                <>
                  <span style={{ fontWeight: 100 }}>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  {notificacao.mensagem.substr(0, 30)}
                </>
              )}
              {notificacao.arquivo && (
                <>
                  <span style={{ fontWeight: 100 }}>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  <a href={notificacao.arquivo.url} target="_blank" rel="noopener noreferrer">Visualizar arquivo</a>
                </>
              )}
              {notificacao.link && (
                <>
                  <span style={{ fontWeight: 100 }}>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  <a href={notificacao.link} target="_blank" rel="noopener noreferrer">Visualizar link</a>
                </>
              )}
            </List.Header>
            <List.Description style={{ fontSize: '1.1em' }}>
              {notificacao.dataEnvio ? <>Enviado {moment(notificacao.dataEnvio).fromNow()} por {notificacao.usuario}</> : 'Ainda não enviado'}
            </List.Description>
            <div className="btn-right">
              <Popup
                content="Gerar relatório de envios desta notificação"
                position='top center'
                size="large"
                trigger={
                  <Button
                    color="blue"
                    size="large"
                    onClick={onClickRel}
                  >
                    Rel. de Envios
                  </Button>
                }
              />
              {getEnviarAction()}
              <Button
                as={Link}
                to={`/condominios/${notificacao.condominio}/notificacoes/editar/${notificacao._id}`}
                className="btn-ui-1"
                size="large"
              >
                Editar
              </Button>
              {getRemoveAction()}
            </div>
          </Responsive>
          <Responsive maxWidth={767} className="notificacao-mobile">
            <List.Header className="notification-header">
              <span>{tipoNotificacao[notificacao.tipo]}</span>
              {notificacao.mensagem && <span>{notificacao.mensagem.substr(0, 30)}</span>}
              {notificacao.arquivo && <a href={notificacao.arquivo.url} target="_blank" rel="noopener noreferrer">Visualizar arquivo</a>}
              {notificacao.link && <a href={notificacao.link} target="_blank" rel="noopener noreferrer">Visualizar link</a>}
            </List.Header>
            <List.Description style={{ fontSize: '1.1em' }}>
              {notificacao.dataEnvio ? <>Enviado {moment(notificacao.dataEnvio).fromNow()} por {notificacao.usuario}</> : 'Ainda não enviado'}
            </List.Description>
            <div className="align-buttons">
              <Button
                color="blue"
                size="large"
                onClick={onClickRel}
              >
                <Icon name="clipboard outline" />
              </Button>
              {getEnviarAction(true)}
              <Button
                as={Link}
                to={`/condominios/${notificacao.condominio}/notificacoes/editar/${notificacao._id}`}
                className="btn-ui-1"
                size="large"
              >
                <Icon name="pencil" />
              </Button>
              {getRemoveAction(true)}
            </div>
          </Responsive>
        </List.Content>
      </List.Item>
    </Transition>
  );
};

export default Notificacao;
