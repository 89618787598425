import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from '../../layouts/MenuVertical';
import { Card, Container, Input, Icon, Button, Form, Transition, Checkbox, Popup } from "semantic-ui-react";
import { create, findEnderecoByCep } from "../../../actions/condominio";
import * as FormValidator from "../../../utils/formValidator";
import Erro from '../../layouts/mensagens/Erro';
import InputMask from "react-text-mask";

class CadastrarCondominio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condominio: {
        nome: "",
        documento: "",
        usarFracaoIdeal: false,
        exibirDocsLinksVotacao: true,
        endereco: {
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          numero: "",
          uf: "",
          complemento: ""
        }
      },
      errors: {},
      sucesso: false,
      loading: false,
      loadingCep: false,
      ruaBairroManual: false,
      enderecoManual: false,
    };
  }

  onChangeDocumento = e => {
    this.setState({
      condominio: { ...this.state.condominio, [e.target.name]: e.target.value.replace(/\D/g, '') },
      errors: {}
    });
  }

  onChange = e => {
    this.setState({
      condominio: { ...this.state.condominio, [e.target.name]: e.target.value },
      errors: {}
    });
  };

  onChangeEndereco = async e => {
    const ufSizeValid = e.target.name !== 'uf' || (e.target.name === 'uf' && e.target.value.length <= 2);
    const cepSizeValid = e.target.name !== 'cep' || (e.target.name === 'cep' && e.target.value.length <= 8);

    if (ufSizeValid && cepSizeValid) {
      this.setState({
        condominio: {
          ...this.state.condominio,
          endereco: {
            ...this.state.condominio.endereco,
            [e.target.name]: e.target.value
          }
        },
        errors: {}, sucesso: false
      });

      if (e.target.name === "cep" && e.target.value && !isNaN(e.target.value) && e.target.value.length === 8) {
        this.setState({ loadingCep: true });

        const endereco = await this.props.findEnderecoByCep(e.target.value);
        if (endereco) {
          this.setState({
            condominio: {
              ...this.state.condominio,
              endereco: {
                ...this.state.condominio.endereco,
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                cidade: endereco.localidade,
                uf: endereco.uf,
              }
            },
            ruaBairroManual: !endereco.logradouro || !endereco.bairro,
            loadingCep: false,
          });
        } else {
          this.setState({
            condominio: {
              ...this.state.condominio,
              endereco: {
                ...this.state.condominio.endereco,
                logradouro: '',
                bairro: '',
                cidade: '',
                numero: '',
                uf: '',
                complemento: '',
              }
            },
            ruaBairroManual: false,
            enderecoManual: true,
            loadingCep: false,
          });
        }
      }
    }
  };

  onChangeUsarFracaoIdeal = (e, { name, checked }) => {
    this.setState({
      condominio: { ...this.state.condominio, [name]: checked },
      errors: {},
    });
  };

  onExibirDocsLinksVotacao = (e, { name, checked }) => {
    this.setState({
      condominio: { ...this.state.condominio, [name]: checked },
      errors: {},
    });
  };

  onVotoSecreto = (e, { name, checked }) => {
    this.setState({
      condominio: { ...this.state.condominio, [name]: checked },
      errors: {},
    });
  };

  validate = (condominio) => {
    const errors = {};

    if (FormValidator.isVazio(condominio.nome)) {
      errors.nome = "Nome é obrigatório";
    }

    if (FormValidator.isVazio(condominio.documento)) {
      errors.documento = "Documento é obrigatório";
    } else if (!FormValidator.isCpf(condominio.documento) && !FormValidator.isCnpj(condominio.documento)) {
      errors.documento = 'Documento inválido';
    }

    if (!FormValidator.isVazio(condominio.endereco.cep) && !FormValidator.isCep(condominio.endereco.cep)) {
      errors.cep = "CEP inválido";
    }

    return errors;
  };

  onSubmitCadastrar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.condominio);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.create(this.state.condominio);
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors
      })
    }
  };

  render() {
    const { condominio, errors, loading, loadingCep, sucesso, ruaBairroManual, enderecoManual } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <div className="corpo-dashboard">
          <MenuVertical page="condominios" />
          <Container className="ui-crud-container" fluid>
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation="fade up"
              transitionOnMount={true}
              visible={sucesso}
            >
              <div style={{
                textAlign: "center",
                marginTop: "40px"
              }}>
                <Icon name="check circle outline" size="massive" color="green" />
                <br />
                <h3>Cadastrado realizado com sucesso</h3>
                <Link to="/" size="small">Voltar</Link>
              </div>
            </Transition>
            {!sucesso &&
              <Form onSubmit={this.onSubmitCadastrar} loading={loading} autoComplete={"off"} size="large">
                <div className="ui-crud-form">
                  <h3>Cadastro de Condomínio / Sociedade / Associação</h3>
                  <Form.Group>
                    <Form.Field error={!!errors.nome} width={11}>
                      <label htmlFor="nome">Nome</label>
                      <Input
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        onChange={this.onChange}
                        value={condominio.nome}
                      />
                      <Erro text={errors.nome} />
                    </Form.Field>
                      <Form.Field error={!!errors.documento} width={5}>
                          <label htmlFor="documento">Documento</label>
                          <Input
                              type="text"
                              children={
                                  <InputMask
                                      id="documento"
                                      name="documento"
                                      placeholder="CPF ou CNPJ"
                                      onInput={this.onChangeDocumento}
                                      mask={val =>
                                          (val.replace(/\D/g, '').length <= 11 ?
                                                  [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                                                               : [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                          )
                                      }
                                      guide={true}
                                  />
                              }
                          />
                          <Erro text={errors.documento} />
                      </Form.Field>
                  </Form.Group>

                    <Card fluid>
                        <Card.Content>
                            <Card.Description>
                              <strong>Endereço</strong>
                            </Card.Description>
                            <Form.Group widths={2} style={{ marginTop: 20 }}>
                                <Form.Field error={!!errors.cep}>
                                    <label htmlFor="cep">CEP</label>
                                    <Input
                                        loading={loadingCep}
                                        name="cep"
                                        type="text"
                                        placeholder="CEP"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.cep}
                                        icon={(condominio.endereco.cidade) ? { name: 'check', color: 'green' } : 'search'}
                                    />
                                    <Erro text={errors.cep} />
                                </Form.Field>
                                <Form.Field error={!!errors.logradouro}>
                                    <label htmlFor="logradouro">Logradouro</label>
                                    <Input
                                        name="logradouro"
                                        type="text"
                                        placeholder="Logradouro"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.logradouro}
                                        readOnly={!ruaBairroManual && !enderecoManual}
                                        disabled={loadingCep}
                                    />
                                    <Erro text={errors.logradouro} />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths={4}>
                                <Form.Field error={!!errors.bairro}>
                                    <label htmlFor="bairro">Bairro</label>
                                    <Input
                                        name="bairro"
                                        type="text"
                                        placeholder="Bairro"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.bairro}
                                        readOnly={!ruaBairroManual && !enderecoManual}
                                        disabled={loadingCep}
                                    />
                                    <Erro text={errors.bairro} />
                                </Form.Field>

                                <Form.Field error={!!errors.numero}>
                                    <label htmlFor="numero">Número</label>
                                    <Input
                                        name="numero"
                                        type="text"
                                        placeholder="Número"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.numero}
                                    />
                                    <Erro text={errors.numero} />
                                </Form.Field>

                                <Form.Field error={!!errors.cidade}>
                                    <label htmlFor="cidade">Cidade</label>
                                    <Input
                                        name="cidade"
                                        type="text"
                                        placeholder="Cidade"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.cidade}
                                        readOnly={!enderecoManual}
                                        disabled={loadingCep}
                                    />
                                    <Erro text={errors.cidade} />
                                </Form.Field>

                                <Form.Field error={!!errors.uf}>
                                    <label htmlFor="uf">UF</label>
                                    <Input
                                        name="uf"
                                        type="text"
                                        placeholder="UF"
                                        onChange={this.onChangeEndereco}
                                        value={condominio.endereco.uf}
                                        readOnly={!enderecoManual}
                                        disabled={loadingCep}
                                    />
                                    <Erro text={errors.uf} />
                                </Form.Field>
                            </Form.Group>
                        </Card.Content>
                    </Card>

                    <Card fluid>
                        <Card.Content>
                            <Card.Description>
                                <strong>Configurações Adicionais</strong>
                            </Card.Description>

                            <Form.Group style={{ marginTop: 20 }}>
                                <Form.Field width={5}>
                                    <label htmlFor="usarFracaoIdeal" style={styles.votacaoIniciadaLabel}>Desativar/Ativar Fração Ideal</label>
                                    <Popup
                                        trigger={
                                            <Checkbox
                                                toggle
                                                name="usarFracaoIdeal"
                                                onChange={this.onChangeUsarFracaoIdeal}
                                                checked={condominio.usarFracaoIdeal} style={{ marginTop: "10px" }}
                                            />
                                        }
                                        content='Clique aqui para desativar ou ativar a fração ideal'
                                        size='large'
                                        position='top center'
                                    />
                                </Form.Field>

                                <Form.Field width={7}>
                                    <label htmlFor="exibirDocsLinksVotacao" style={styles.votacaoIniciadaLabel}>Ocultar/Exibir Documentos e Links de Acesso</label>
                                    <Popup
                                        trigger={
                                            <Checkbox
                                                toggle
                                                name="exibirDocsLinksVotacao"
                                                onChange={this.onExibirDocsLinksVotacao}
                                                checked={condominio.exibirDocsLinksVotacao} style={{ marginTop: "10px" }}
                                            />
                                        }
                                        content='Clique aqui para ocultar ou exibir os documentos e links de acesso nos resultados das votações para os usuários.'
                                        size='large'
                                        position='top center'
                                    />
                                </Form.Field>

                                <Form.Field width={4}>
                                    <label htmlFor="votoSecreto" style={styles.votacaoIniciadaLabel}>Voto Secreto</label>
                                    <Popup
                                        trigger={
                                            <Checkbox
                                                toggle
                                                name="votoSecreto"
                                                onChange={this.onVotoSecreto}
                                                checked={condominio.votoSecreto} style={{ marginTop: "10px" }}
                                            />
                                        }
                                        content='Clique aqui para habilitar o voto secreto para o condomínio / sociedade / associação. Está opção só pode ser habilitada neste momento. Após a criação, NÃO é possível alterar esta opção.'
                                        size='large'
                                        position='top center'
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Card.Content>
                    </Card>

                  <div style={styles.divBtn}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      className="btn-ui-1"
                      size="large"
                    >
                      Cadastrar Condomínio / Sociedade / Associação
                    </Button>
                  </div>
                </div>
              </Form>
            }
          </Container>
        </div>
      </div>
    );
  }
}

const styles = {
  divBtn: {
    textAlign: "center",
    marginTop: "30px"
  }
};

const mapStateToProps = (state) => {
  return {
    condominio: state.condominio.condominio,
    condominios: state.condominio.condominios,
    usuario: state.user.user,
    errors: state.condominio.errors
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create,
    findEnderecoByCep
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastrarCondominio);
