import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { findById, findByIdAndUpdate } from '../../../actions/notificacao'
import { findById as findCondominioById } from '../../../actions/condominio'
import { find as findUnidadeByCondominioId } from '../../../actions/unidade'
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { Container, Transition, Icon, Form, Segment, Input, TextArea, Button, Popup, Label } from 'semantic-ui-react';
import Erro from '../../layouts/mensagens/Erro';
import * as FormValidator from "../../../utils/formValidator";

class EditarNotificacao extends Component {

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      notificacao: props.notificacao,
      errors: {},
      sucesso: false,
      loading: false,
    };

    this.pararBusca = this.pararBusca.bind(this);
  }

  componentDidMount = async () => {
    await this.props.findCondominioById(this.props.match.params.condominioId);
    await this.props.findUnidadeByCondominioId(this.props.match.params.condominioId);
    await this.props.findById(this.props.match.params.id);

    this.setState({
      notificacao: {
        ...this.props.notificacao,
        unidades: this.props.notificacao.unidades.length > 0 ? this.props.notificacao.unidades : [],
        usuario: this.props.notificacao.usuario ? this.props.notificacao.usuario._id : null,
      },
      errors: {
        ...this.props.errorsCondominio,
        ...this.props.erros
      }
    })
  };

  pararBusca = () => {
    clearTimeout(this.state.busca);
  }

  validate = notificacao => {
    const errors = {}

    if (FormValidator.isVazio(notificacao.tipo)) {
      errors.tipo = 'Tipo é obrigatório';
    }

    if (!FormValidator.isVazio(notificacao.link) && !FormValidator.isUrl(notificacao.link)) {
      errors.link = 'Url inválida';
    }

    if (FormValidator.isVazio(notificacao.mensagem) && !this.state.file) {
      errors.mensagem = 'Adicione uma mensagem ou arquivo';
    }

    return errors;
  }

  onChangeFile = e => {
    const mime = ['application/pdf'];
    if (e.target.files && e.target.files[0]) {
      const type = e.target.files[0].type;
      if (mime.indexOf(type) !== -1 || type.substr(0, 5) === 'image') {
        if (FormValidator.sizeLimit(e.target.files[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
          this.setState({
            file: e.target.files[0],
            errors: {},
          });
        } else {
          this.setState({
            errors: {
              arquivo: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb`
            }
          });
        }
      }
    }
  }

  onChangeSelect = (_, { name, value }) =>
    this.setState({
      notificacao: { ...this.state.notificacao, [name]: value },
      errors: {}
    })

  onChange = e =>
    this.setState({
      notificacao: { ...this.state.notificacao, [e.target.name]: e.target.value },
      errors: {}
    })

  getTipos = () => [
    { key: 'DEFAULT', value: 'DEFAULT', text: 'Padrão' },
    { key: 'CONVOCATION', value: 'CONVOCATION', text: 'Convocação' },
  ]

  getUnidades = unidades =>
    unidades.map(unidade => ({
      key: unidade._id,
      value: unidade._id,
      text: `${unidade.bloco} ${unidade.numero}`,
    }))

  fileUpload = file => {
    const formData = new FormData();
    formData.append('arquivo', file);
    Object.keys(this.state.notificacao).filter(key => key !== 'arquivo').forEach(item => {
      formData.append(item, this.state.notificacao[item]);
    });
    return formData;
  }

  onSubmitEditar = async e => {
    e.preventDefault();
    const errors = this.validate(this.state.notificacao);
    this.setState({ errors });

    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(this.state.notificacao._id, this.fileUpload(this.state.file));
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors
      });
    }
  }

  render() {
    const { notificacao, errors, loading, sucesso, file } = this.state;
    const { condominio, unidades } = this.props;

    return (
      <div>
        {!!errors.global && <Redirect to="/" />}
        <MenuHorizontal />
        <div className="corpo-dashboard-mini">
          <MenuVertical condominio={condominio} loading={loading} />
          <Container style={{ padding: 20 }}>
            <Erro text={errors.error} tipo="block" />
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation="fade up"
              transitionOnMount={true}
              visible={sucesso}
            >
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 40
                }}
              >
                <Icon
                  name="check circle outline"
                  size="massive"
                  color="green"
                />
                <br />
                <h3>Notificação atualizada com sucesso</h3>
                <Link
                  to={`/condominios/${condominio._id}/notificacoes`}
                  size="small"
                >
                  Voltar
                </Link>
              </div>
            </Transition>
            {!sucesso && (
              <Form
                onSubmit={this.onSubmitCadastrar}
                loading={loading}
                autoComplete="off"
                size="large"
              >
                <div className="ui-crud-form">
                  <h3>Editar Notificação</h3>
                  <Segment padded>
                    <Form.Field error={!!errors.arquivo} inline>
                      <label style={{ display: "block", marginBottom: "5px" }}>Arquivo (Imagem / Documento PDF)</label>
                      <Popup
                        trigger={
                          <label htmlFor="arquivo" className="label-ui-arquivo">
                            {!this.state.file
                              ?
                              <span><Icon name="paperclip" /> Selecionar arquivo</span>
                              :
                              <span><Icon name="file pdf outline" /> {file.name}</span>
                            }
                            <Input
                              type="file"
                              id="arquivo"
                              name="arquivo"
                              style={{ display: "none" }}
                              onChange={this.onChangeFile}
                              accept="image/*|application/pdf"
                            />
                          </label>
                        }
                        content="Selecione um arquivo (imagem ou documento PDF)"
                      />
                      <br />
                      {!!notificacao.arquivo && notificacao.arquivo.url && (<a href={notificacao.arquivo.url} target="_blank" rel="noopener noreferrer">Ver arquivo atual</a>)}
                      <Erro text={errors.arquivo} />
                      <br />
                    </Form.Field>
                    <Form.Group widths={2}>
                      <Form.Field error={!!errors.tipo}>
                        <Form.Select
                          fluid
                          label='Tipo'
                          name="tipo"
                          options={this.getTipos()}
                          onChange={this.onChangeSelect}
                          value={notificacao.tipo}
                          placeholder='Selecione o tipo de notificação'
                        />
                        <Erro text={errors.tipo} />
                      </Form.Field>
                      <Form.Field>
                        <Form.Select
                          fluid
                          label='Unidades votantes'
                          name="unidades"
                          options={this.getUnidades(unidades)}
                          onChange={this.onChangeSelect}
                          value={notificacao.unidades}
                          placeholder='Enviar para todas as unidades votantes'
                          multiple={true}
                        />
                        <Label pointing>Deixe em branco para enviar para todas as unidades votantes</Label>
                      </Form.Field>
                    </Form.Group>
                    <Form.Field error={!!errors.link}>
                      <label htmlFor="link">Link de acesso ou transmissão</label>
                      <Input
                        name="link"
                        type="text"
                        placeholder="Link de acesso ou transmissão Zoom, Youtube, Meet, Teams, Skype, entre outros."
                        onChange={this.onChange}
                        value={notificacao.link}
                      />
                      <Erro text={errors.link} />
                    </Form.Field>
                    <Form.Field error={!!errors.mensagem}>
                      <label htmlFor="link">Mensagem</label>
                      <TextArea
                        name="mensagem"
                        placeholder="Mensagem"
                        onChange={this.onChange}
                        value={notificacao.mensagem}
                      />
                      <Erro text={errors.mensagem} />
                    </Form.Field>
                    <div style={{ textAlign: "center" }}>
                      <br />
                      <Button
                        loading={loading}
                        disabled={loading}
                        className="btn-ui-1"
                        onClick={this.onSubmitEditar}
                        size="large"
                      >
                        Salvar
                      </Button>
                    </div>
                  </Segment>
                </div>
              </Form>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notificacao: state.notificacao.notificacao,
  errors: state.notificacao.errors,
  condominio: state.condominio.condominio,
  unidades: state.unidade.unidades,
  errorsCondominio: state.condominio.errors
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findCondominioById,
  findUnidadeByCondominioId,
  findById,
  findByIdAndUpdate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditarNotificacao);
