import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from "../../layouts/MenuVertical";
import { Segment, Step, Icon, Transition, Form, Button, Grid, Container, Loader, Input } from "semantic-ui-react";
import { create } from "../../../actions/pagamento";
import moment from 'moment';
import { formatDecimal, getMesAno } from "../../../utils/formatador";
import api from "../../../api";
import Erro from '../../layouts/mensagens/Erro';
import { Redirect } from "react-router-dom";

import './Pagamento.css';

class Pagamento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      condominio: {},
      loading: true,
      loadingCupom: false,
      activeItem: 'unidades',
      quantidade: 1,
      input_cupom: '',
      cupom: null,
      planos: [],
      planoSelecionado: null,
      errors: {
        cupom: null,
      }
    };
  }

  componentDidMount = async () => {
    try {
      const condominio = await api.condominio.findSimpleById(this.props.match.params.id);
      const planos = await api.pagamento.planos();

      this.setState({ loading: false, condominio, planos });

      if (this.isCondominioAtivo()) {
        this.setState({
          planoSelecionado: this.state.planos.find(it => it.value === (this.state.condominio.plano || 'PLANO_3')),
        });
      }

    } catch(e) {
      this.setState({ loading: false, errors: e.data.errors });
    }
    if (this.isRenovacao()) {
      this.setState({ quantidade: this.state.condominio.totalUnidades });
    }
  }

  onChangeSlider = e =>
    this.setState({
      [e.target.name]: parseInt(e.target.value.replace(/\D/g, ''), 10) || 0,
      errors: {}
    });

  resetQuantidade = e => {
    if (this.state.quantidade === 0) {
      this.setState({
        [e.target.name]: 1,
        errors: {}
      });
    }
  }

  isCondominioAtivo = () => {
    // Dias para liberar a renovação de um plano ainda ativo
    const days = !this.state.condominio.plano || this.state.condominio.plano !== 'PLANO_1' ? 15 : 5;
    return this.state.condominio.dataVencimento && moment(this.state.condominio.dataVencimento).diff(moment(), 'days') > days;
  }

  isRenovacao = () =>
    !this.isCondominioAtivo() && this.state.condominio.dataPagamento && this.state.condominio.dataVencimento;

  confirmarCompra = async () => {
    this.setState({ activeItem: 'pagamento' });

    const pagamento = await this.props.create({
      quantidade: this.state.quantidade,
      condominio: this.state.condominio._id,
      cupom: !!this.state.cupom && this.state.cupom.codigo,
      plano: this.state.planoSelecionado.value,
    });

    this.setState({
      loading: false,
      pagamento,
      errors: { ...this.props.errors, ...this.props.errorsPagamento },
    });
  }

  getProximaDataPagamento = () => {
    return getMesAno(moment().add((this.state.planoSelecionado && this.state.planoSelecionado.meses) || 0, 'month').toDate());
  }

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  aplicarCupom = async () => {
    if (this.state.input_cupom) {
      this.setState({ loadingCupom: true });
      try {
        const cupom = await api.cupom.check(this.state.input_cupom);
        this.setState({ loadingCupom: false, cupom });
      } catch(e) {
        this.setState({
          loadingCupom: false,
          errors: { cupom: !!e.data && !!e.data.errors && e.data.errors.error },
        });
      }
    }
  }

  changePlano = ({ currentTarget }) => {
    const plano = currentTarget.getAttribute('plano');
    this.setState({ planoSelecionado: this.state.planos.find(it => it.value === plano) });
  }

  getItemPlano = (plano, disableClick, dataValidade) => {
    const selecionado = this.state.planoSelecionado && this.state.planoSelecionado.value === plano.value;
    const classNames = ['plano'];

    if (disableClick) {
      classNames.push('no-click');
    }

    if (selecionado) {
      classNames.push('selected');
    }

    return (
      <Segment padded={true} className={classNames.join(' ')} onClick={disableClick ? null : this.changePlano} plano={plano.value}>
        <h3>{plano.label}</h3>
        <span>R$ <b>{formatDecimal(plano.valorCondominio, ',', true)}</b></span>
        <ul style={plano.meses === 1 ? { marginTop: 32, marginBottom: 33 } : {}}>
          {plano.meses > 1 && (
            <li><Icon name="check" />Equivalente à R$ {formatDecimal(plano.valorCondominio / plano.meses)}/mês</li>
          )}
          <li><Icon name="check" />R$ {formatDecimal(plano.valorUnidade)}/unidade votante</li>
          <li><Icon name="check" />Assembléias ilimitadas</li>
          <li><Icon name="check" />Aplicativo de votação</li>
        </ul>
        <div className="ui-validade-plano">
          <p>Validade: {dataValidade ? getMesAno(dataValidade) : getMesAno(moment().add(plano.meses, 'month').toDate())}</p>
        </div>
        {!disableClick && selecionado && (
          <div className="selected">
            <Icon name="check" />
          </div>
        )}
      </Segment>
    );
  };

  getHeader = () => {
    let result = null;

    if (this.isCondominioAtivo()) {
      const plano = this.state.planos.find(it => it.value === (this.state.condominio.plano || 'PLANO_3'));
      result = (
        <>
          <h1>Meu plano</h1>
          <div style={{ maxWidth: 290, margin: '0 auto 40px' }}>
            {this.getItemPlano(plano, true, this.state.condominio.dataVencimento)}
          </div>
          {!!this.state.planoSelecionado && <h1>Adicionar mais unidades votantes</h1>}
        </>
      );
    } else {
      result = (
        <>
          <h1>Selecione um plano</h1>
          <Grid stackable={true} style={{ maxWidth: 940, margin: '0 auto' }}>
            <Grid.Row columns={3}>
              {this.state.planos.map(it => <Grid.Column key={it.value}>{this.getItemPlano(it, false)}</Grid.Column>)}
            </Grid.Row>
          </Grid>
          {!!this.state.planoSelecionado && <h1>Selecione a quantidade de unidades votantes desejada</h1>}
        </>
      );
    }

    return result;
  }

  getSubTotal = () => {
    let subTotal = this.state.planoSelecionado.valorUnidade * this.state.quantidade;
    if (!this.isCondominioAtivo()) {
      subTotal += this.state.planoSelecionado.valorCondominio;
    }
    return subTotal;
  }

  getTotal = () => {
    let total = this.getSubTotal();
    if (this.state.cupom) {
      total *= 100 - this.state.cupom.porcentagem;
      total /= 100;
    }
    return total;
  }

  render() {
    const {
      condominio,
      activeItem,
      quantidade,
      loading,
      pagamento,
      input_cupom,
      cupom,
      loadingCupom,
      planoSelecionado,
      errors
    } = this.state;

    return (
      <div>
        {!!pagamento && pagamento.origem === 'MANUAL' && <Redirect to={`/condominios/${condominio._id}/comprar/sucesso`} />}
        <MenuHorizontal />
        <div className="corpo-dashboard">
          <MenuVertical condominio={condominio} />
          <Segment padded>
            <div>
              <Step.Group widths={3} size="large">
                <Step active={activeItem === 'unidades'} completed={activeItem !== 'unidades'}>
                  <Icon name="building" />
                  <Step.Content className="ui-pagina-creditos">
                    <Step.Title>Unidades votantes</Step.Title>
                    <Step.Description>
                      Informe a quantidade de unidades votantes
                    </Step.Description>
                  </Step.Content>
                </Step>
                <Step disabled={activeItem === 'unidades'} active={activeItem === 'pagamento'}>
                  <Icon name="payment" />
                  <Step.Content className="ui-pagina-creditos">
                    <Step.Title>Pagamento</Step.Title>
                    <Step.Description>
                      Selecione a forma de pagamento
                    </Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              <Transition
                animation="fade up"
                duration={{ show: 700, hide: 0 }}
                visible={activeItem === 'unidades'}
                transitionOnMount={true}
              >
                <div className="ui-card-creditos">
                  <div style={{ textAlign: 'center', marginTop: 30 }}>
                    {this.getHeader()}
                  </div>
                  {!!planoSelecionado && (
                    <>
                      <div style={{ textAlign: 'center', marginTop: 30 }}>
                        <input
                          type="range"
                          min="1"
                          max="999"
                          value={quantidade}
                          name="quantidade"
                          className="ui-slider"
                          onChange={this.onChangeSlider}
                        ></input>
                      </div>
                      <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <Form size="huge" autoComplete="off">
                          <Form.Field>
                            <input
                              type="text"
                              name="quantidade"
                              placeholder="Quantidade"
                              value={quantidade}
                              onChange={this.onChangeSlider}
                              onBlur={this.resetQuantidade}
                              autoFocus={true}
                              className="ui-input-creditos"
                            />
                            <label style={{ fontSize: '1.4em' }}>
                              unidade{quantidade !== 1 && 's'} votante{quantidade !== 1 && 's'}
                            </label>
                          </Form.Field>
                        </Form>
                        {this.isCondominioAtivo() && (
                          <Grid stackable style={{ maxWidth: 900, margin: '30px auto' }}>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                <Segment style={{ maxWidth: 450, margin: 'auto', textAlign: 'left' }} padded>
                                  <h3>Atual</h3>
                                  <h3>
                                    <Icon name="building outline" style={{ color: "#e83c6e" }} />
                                    <span style={{ color: "#e83c6e" }}>{this.state.condominio.totalUnidades}</span>
                                    <span> unidade{this.state.condominio.totalUnidades !== 1 && 's'} votante{this.state.condominio.totalUnidades !== 1 && 's'}</span>
                                  </h3>
                                </Segment>
                              </Grid.Column>
                              <Grid.Column>
                                <Segment style={{ maxWidth: 450, margin: 'auto', textAlign: 'left' }} padded>
                                  <h3>Após adicionar <span style={{ color: "#e83c6e" }}>{quantidade}</span> unidade{quantidade !== 1 && 's'}</h3>
                                  <h3>
                                    <Icon name="building outline" style={{ color: "#e83c6e" }} />
                                    <span style={{ color: "#e83c6e" }}>{this.state.condominio.totalUnidades + quantidade}</span>
                                    <span> unidade{(this.state.condominio.totalUnidades + quantidade) !== 1 && 's'} votante{(this.state.condominio.totalUnidades + quantidade) !== 1 && 's'}</span>
                                  </h3>
                                </Segment>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        )}
                        <h3>
                          <Icon name="calendar alternate outline" />
                          Total a pagar
                        </h3>
                        <div>
                          <h1
                            style={{
                              ...styles.precoTotal,
                              display: 'inline-block',
                              ...(
                                !!cupom ? {
                                  textDecoration: 'line-through',
                                  color: '#666',
                                  fontSize: 18,
                                } : {}
                              )
                            }}
                          >
                            R$ {formatDecimal(this.getSubTotal())}
                          </h1>
                          {!!cupom && (
                            <div style={{ display: 'inline-block', marginLeft: 10 }}>
                              <h1 style={styles.precoTotal}>
                                R$ {formatDecimal(this.getTotal())}
                              </h1>
                            </div>
                          )}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                          {!!cupom ? (
                            <h3 style={{ marginBottom: 2 }}>{cupom.descricao} - {cupom.porcentagem}% de desconto</h3>
                          ) : (
                            <>
                              <h3 style={{ marginBottom: 2 }}>Possui um cupom de desconto?</h3>
                              <Input
                                name="input_cupom"
                                type="text"
                                placeholder="MEUCUPOM10"
                                onChange={this.onChange}
                                value={input_cupom}
                              />
                              <Button
                                loading={loadingCupom}
                                positive={true}
                                name="cupom-desconto"
                                onClick={this.aplicarCupom}
                                style={{ marginLeft: 10 }}
                                disabled={loadingCupom || !input_cupom}
                              >
                                Aplicar
                              </Button>
                              <Erro text={errors.cupom} />
                            </>
                          )}
                        </div>
                        <Button
                          loading={loading}
                          disabled={loading}
                          className="btn-ui-1"
                          size="large"
                          name="pagamento"
                          onClick={this.confirmarCompra}
                        >
                          Prosseguir para o pagamento
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Transition>
              <Transition
                animation="fade up"
                duration={{ show: 800, hide: 0 }}
                visible={activeItem === 'pagamento'}
              >
                <div className="ui-card-creditos" style={{ marginTop: 20 }}>
                  <Container style={{ textAlign: 'center' }}>
                    <div style={styles.pagamentoInfo}>
                      <h2 style={{ textAlign: 'left', marginBottom: -10, marginTop: 50}}>
                        Você está comprando o direito de criar
                        <span style={{ color: "#e83c6e" }}> {quantidade}</span>
                        <span> unidade{quantidade !== 1 && 's'} votante{quantidade !== 1 && 's'}</span>
                      </h2>
                      <h2 style={{ textAlign: 'left', marginBottom: -10 }}>
                        <span>Total a pagar: </span>
                        {pagamento && pagamento.valor
                          ? ('R$ ' + formatDecimal(pagamento.valor))
                          : <Loader inline active size="small" />
                        }
                      </h2>
                      <h3 style={{ textAlign: 'left', marginBottom: 40 }}>
                        <Icon name="calendar alternate outline" />
                        <span>Depois você só paga pela renovação em </span>
                        <span style={{ color: "#e83c6e" }}>
                          {this.isCondominioAtivo()
                            ? getMesAno(condominio.dataVencimento)
                            : this.getProximaDataPagamento()
                          }
                        </span>
                      </h3>
                      <Button
                        className="btn-ui-1"
                        size="large"
                        as="a"
                        fluid
                        href={pagamento && pagamento.urlPagamento}
                        target="_blank"
                        style={{ padding: 15 }}
                        loading={pagamento && !pagamento.urlPagamento}
                        disabled={pagamento && !pagamento.urlPagamento}
                      >
                        Selecionar Forma de Pagamento e Pagar Agora
                      </Button>
                      <h3>Você será redirecionado para a página de pagamento do Nitrus</h3>
                      <p style={{ fontFamily: 'Geomanist', fontWeight: 300 }}>
                        Você receberá um email com a confirmação do pagamento e assim que o mesmo for confirmado o sistema irá liberar sua compra de Unidades. &nbsp;
                        <strong>
                          Caso o pagamento seja feito por Boleto Bancário é necessário aguardar o tempo de compensação de 1 a 3 dias úteis só depois dessa compensação que o sistema será habilitado.
                          Para pagamentos com PIX a habilitação será feita em poucos minutos.&nbsp;
                        </strong>
                        Fique tranquilo nós acompanharemos todo o processo com você!</p>
                      <img src="/images/pagamento/nitrus.png" alt="Nitrus" height="100" style={{ marginTop: 10 }} />
                    </div>
                  </Container>
                </div>
              </Transition>
            </div>
          </Segment>
        </div>
      </div>
    );
  }
}

const styles = {
  precoTotal: {
    fontSize: '3em',
    borderRadius: 5,
    marginBottom: 30,
    color: '#e83c6e'
  },
  pagamentoInfo: {
    margin: 'auto',
    maxWidth: 650,
    width: '100%'
  },
};

const mapStateToProps = state => ({
  pagamento: state.pagamento.pagamento,
  errorsPagamento: state.pagamento.errors,
});

const mapDispatchToProps = dispatch => bindActionCreators({ create }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pagamento);
