import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormLogin from "../../forms/FormLogin";
import FormRecuperacaoSenha from "../../forms/FormRecuperacaoSenha";
import { login, enviarEmailRecuperacaoSenha } from "../../../actions/auth";
import { Container, Card, Transition } from "semantic-ui-react";
import MenuHorizontal from "../../layouts/MenuHorizontal";

class Login extends Component {
  state = {
    pagina: true
  };

  componentDidMount = () => {
    if (this.props.match.params.action)
      (this.props.match.params.action === "recuperar-senha") ? this.setState({ pagina: false }) : this.props.history.push("/login");
  }

  submitLogin = data =>
    this.props.login(data.email, data.senha, data.token).then(() => this.props.history.push(this.props.redirect));

  submitEnviarEmailRecuperacaoSenha = data =>
    this.props.enviarEmailRecuperacaoSenha(data.email);

  submitRecuperarSenha = data =>
    this.props.recuperarSenha(data)
      .then(usuario => usuario);

  trocaPagina = () => {
    this.setState({ pagina: !this.state.pagina })
  }

  render() {
    const { pagina } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <div className="ui-pagina-login">
          <div className="ui-pagina-login-gradient-img"></div>
          <Container>
            <Transition animation="fade down" duration={{ show: 1000, hide: 0 }} visible={true} transitionOnMount={true}>
              <Card fluid style={styles.card} className="card-auth">
                <Card.Content>
                  <Card.Header style={styles.header}>
                    <img src="/images/logo-voteassembleia-com-escrita.png" alt="Vote Assembleia logo" height="64" style={{ marginBottom: "15px" }} />
                    <Transition duration={{ show: 600, hide: 0 }} animation="fly left" visible={pagina}>
                      <div>
                        <span className="ui-title">Login</span>
                        {this.props.redirect !== "/" &&
                          <p style={styles.messageRedirect}>Você precisa estar logado para acessar essa página</p>
                        }
                      </div>
                    </Transition>
                    <Transition duration={{ show: 600, hide: 0 }} animation="fly left" visible={!pagina}>
                      <div>
                        <span className="ui-title">Esqueci minha senha</span>
                      </div>
                    </Transition>
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  {(pagina) ?
                    <FormLogin submit={this.submitLogin} />
                    :
                    <FormRecuperacaoSenha submit={this.submitEnviarEmailRecuperacaoSenha} />
                  }
                </Card.Content>
                <Card.Content>
                  <Transition duration={{ show: 600, hide: 0 }} animation="fly left" visible={pagina}>
                    <div style={styles.cardLink} onClick={this.trocaPagina}>
                      Esqueci minha senha
                    </div>
                  </Transition>
                  <Transition duration={{ show: 600, hide: 0 }} animation="fly left" visible={!pagina}>
                    <div style={styles.cardLink} onClick={this.trocaPagina}>
                      Fazer Login
                    </div>
                  </Transition>
                </Card.Content>
              </Card>
            </Transition>
          </Container>
          <p style={styles.copyright}>
            VOTE ASSEMBLEIA {new Date().getFullYear()}. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Link to="/politica-de-privacidade" className="rodape-link">Política de Privacidade</Link>
          </p>
          <p style={styles.copyright}>
            © 2017 - {new Date().getFullYear()} APP4ALL TECNOLOGIA DA INFORMAÇÃO LTDA.
          </p>
        </div>
      </div >
    );
  }
}

Login.defaultProps = {
  redirect: "/"
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  enviarEmailRecuperacaoSenha: PropTypes.func.isRequired
}

const styles = {
  titulo: {
    fontSize: "20px",
    fontWeight: 100,
    padding: "10px"
  },
  header: {
    padding: "10px 10px",
    textAlign: "center",
  },
  card: {
    padding: "5px 15px 0px 15px",
    margin: "auto",
    marginBottom: "40px",
    marginTop: "80px",
    overflow: "hidden",
    maxWidth: "440px"
  },
  cardLink: {
    fontSize: "13px",
    color: "#2185e0",
    cursor: "pointer",
    textAlign: "center"
  },
  messageRedirect: {
    fontSize: "11px",
    fontWeight: 100,
    marginTop: "8px",
  },
  copyright: {
    maxWidth: "400px",
    padding: "10px 20px",
    fontSize: "8pt",
    textAlign: "center",
    fontFamily: "Geomanist",
    margin: "auto",
    marginTop: "-25px",
    color: "white",
    zIndex: 1,
    position: "relative",
    marginBottom: "15px"
  }
}

export default connect(null, { login, enviarEmailRecuperacaoSenha })(Login);
