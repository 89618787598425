import React from 'react';
import { Segment, Grid, Icon, Popup, Button } from 'semantic-ui-react';

const Documento = ({ documento, submitExcluir }) => {
  const excluir = () => submitExcluir(documento);

  return (
    <Segment style={{ marginBottom: 10, marginTop: 10 }}>
      <Grid verticalAlign="middle" stackable>
        <Grid.Column width={12}>
          <p>
            <a href={documento.url} target="_blank" rel="noopener noreferrer">
              <Icon name="file pdf" /> {documento.nome}
            </a>
          </p>
        </Grid.Column>
        <Grid.Column width={4} className="action-ui-manter">
          <Popup
            content="Excluir Documento"
            position="top center"
            size="small"
            trigger={
              <Button
                className="primary"
                as="span"
                icon
                basic
                size="tiny"
                onClick={excluir}
              >
                <Icon name='trash alternate' />
              </Button>
            }
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default Documento;
