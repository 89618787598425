import React, { Component } from "react";
import { Grid } from "semantic-ui-react";

class Rodape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: {
        rodape: {}
      }
    };
  }

  componentDidMount = () => {
    const wrappedElement = document.getElementById("rodape");
    if (window.innerHeight > wrappedElement.getBoundingClientRect().bottom) {
      this.setState({
        styles: {
          rodape: {
            position: "absolute",
            left: "0",
            bottom: "0",
            width: "100%"
          }
        }
      });
    }
  };

  render() {
    return (
      <div
        className="rodape text-center bg-color-primary"
        id="rodape"
        style={this.state.styles.rodape}
      >
        <Grid verticalAlign="middle" columns={1} doubling stackable>
          <Grid.Column>
            <div>
              <p className="rodape-text">
                © 2017 - 2021 APP4ALL TECNOLOGIA DA INFORMAÇÃO LTDA.
              </p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Rodape;
