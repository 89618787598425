import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { Redirect } from 'react-router-dom';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { Container, Grid, Checkbox, Popup, Responsive, Menu, Icon } from 'semantic-ui-react';
import * as FormValidator from '../../../utils/formValidator';
import EditarVotacaoSobre from './EditarVotacaoSobre';
import EditarVotacaoPerguntas from './EditarVotacaoPerguntas';
import EditarVotacaoRelatorios from './EditarVotacaoRelatorios';
import EditarVotacaoResultado from './EditarVotacaoResultado';
import ModalFinalizarVotacao from './ModalFinalizarVotacao';

import './EditarVotacao.css';

const ACOES_VALIDAS = ['sobre', 'perguntas', 'resultados', 'relatorios'];

const EditarVotacao = ({ match, history }) => {
  const [activeItem, setActiveItem] = useState('sobre');
  const [errors, setErrors] = useState({});
  const [condominio, setCondominio] = useState({});
  const [votacao, setVotacao] = useState({});
  const [votacaoPossuiVoto, setVotacaoPossuiVoto] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const init = async () => {
        try {
          if (match.params.action && ACOES_VALIDAS.indexOf(match.params.action) !== -1) {
            setActiveItem(match.params.action);
          }

          setCondominio(await api.condominio.findSimpleById(match.params.condominioId));
          setVotacao(await api.votacao.findForEdit(match.params.id));
          setVotacaoPossuiVoto(await api.votacao.checkPossuiVoto(match.params.id).then(it => it.possuiVotos));
        } catch(e) {
          setErrors(e.data.errors);
        }

        setLoading(false);
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateVotacao = dados => setVotacao(p => ({ ...p, ...dados }));

  const disabledFields = () => votacaoPossuiVoto || !!votacao.votacaoFinalizada;

  const handleItemClick = (_, { name }) => {
    const path = history.location.pathname.replace(activeItem, name);
    setActiveItem(name);

    if (match.params.action && match.params.action !== name) {
      if (path === history.location.pathname) {
        history.push(`${path}/${name}`);
      } else {
        history.push(`${path}`);
      }
    }
  };

  const onChangeVotacaoIniciada = async (_, { checked }) => {
    if (FormValidator.isVazio(votacao.titulo)) {
      setErrors({ titulo: 'Digite um título para a votação' });
    } else {
      setLoading(true)
      try {
        const result = await api.votacao.iniciarVotacaoV2(votacao._id, { condominio: condominio._id, votacaoIniciada: checked });
        updateVotacao(result);
      } catch (e) {
        setErrors(e.data.errors);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard-mini">
        <MenuVertical condominio={condominio} />
        <Container style={{ paddingTop: "10px" }} fluid>
          <div className="ui-crud-form">
            <Grid style={{ paddingTop: "10px" }} columns={3} stackable>
              <Grid.Column>
                <h3 style={{ textAlign: "center" }}>Votação <span style={{ fontWeight: 100 }}>{votacao.titulo}</span></h3>
              </Grid.Column>
              <Grid.Column>
                <div style={styles.votacaoIniciadaAction}>
                  {votacao.votacaoFinalizada ? (
                    <Checkbox
                      toggle
                      checked={votacao.votacaoIniciada}
                      style={{ paddingTop: "30px", margin: "auto" }}
                      disabled={true}
                    />
                  ) : (
                    <Popup
                      trigger={
                        <Checkbox
                          toggle
                          name="votacaoIniciada"
                          onChange={onChangeVotacaoIniciada}
                          checked={votacao.votacaoIniciada}
                          style={{ paddingTop: "30px", margin: "auto" }}
                          disabled={loading}
                        />
                      }
                      content='Clique aqui para bloquear ou desbloquear a votação'
                      size='large'
                      position='top center'
                    />
                  )}
                  <label htmlFor="votacaoIniciada" style={styles.votacaoIniciadaLabel}>Bloquear/Desbloquear Votação</label>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={styles.votacaoFinalizadaAction}>
                  <ModalFinalizarVotacao
                    disabled={!!votacao.votacaoFinalizada}
                    votacao={votacao}
                    loadingVotacao={loading}
                    condominio={condominio}
                    updateVotacao={updateVotacao}
                  />
                </div>
              </Grid.Column>
            </Grid>
            <Responsive minWidth={768}>
              <Menu pointing secondary stackable widths={4} style={{ marginTop: "20px" }}>
                <Menu.Item name='sobre' active={activeItem === 'sobre'} onClick={handleItemClick} className="menu-item-actions">
                  <Icon name="calendar check outline" /> Sobre a Reunião
                </Menu.Item>
                <Menu.Item name='perguntas' active={activeItem === 'perguntas'} onClick={handleItemClick}>
                  <Icon name="question" /> Perguntas / Pautas
                </Menu.Item>
                <Menu.Item name='resultados' active={activeItem === 'resultados'} onClick={handleItemClick}>
                  <Icon name="tasks" /> Resultados
                </Menu.Item>
                <Menu.Item name='relatorios' active={activeItem === 'relatorios'} onClick={handleItemClick}>
                  <Icon name="file alternate outline" /> Relatórios
                </Menu.Item>
              </Menu>
            </Responsive>
            <Responsive maxWidth={767}>
              <Menu pointing secondary widths={4} style={{ marginTop: "30px" }}>
                <Menu.Item name='sobre' active={activeItem === 'sobre'} onClick={handleItemClick} className="menu-item-actions">
                  <Icon name="calendar check outline" />
                </Menu.Item>
                <Menu.Item name='perguntas' active={activeItem === 'perguntas'} onClick={handleItemClick}>
                  <Icon name="question" />
                </Menu.Item>
                <Menu.Item name='resultados' active={activeItem === 'resultados'} onClick={handleItemClick}>
                  <Icon name="tasks" />
                </Menu.Item>
                <Menu.Item name='relatorios' active={activeItem === 'relatorios'} onClick={handleItemClick}>
                  <Icon name="file alternate outline" />
                </Menu.Item>
              </Menu>
            </Responsive>
            <EditarVotacaoSobre
              visible={activeItem === 'sobre'}
              loadingVotacao={loading}
              votacao={votacao}
              condominio={condominio}
              votacaoPossuiVoto={votacaoPossuiVoto}
              updateVotacao={updateVotacao}
            />
            <EditarVotacaoPerguntas
              visible={activeItem === 'perguntas'}
              votacao={votacao}
              condominio={condominio}
              votacaoPossuiVoto={votacaoPossuiVoto}
              handleItemClick={handleItemClick}
              disabledFields={disabledFields}
            />
            <EditarVotacaoResultado
              visible={activeItem === 'resultados'}
              votacao={votacao}
              condominio={condominio}
              history={history}
            />
            <EditarVotacaoRelatorios
              visible={activeItem === 'relatorios'}
              votacao={votacao}
              condominio={condominio}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

const styles = {
  votacaoIniciadaLabel: {
    display: "inline-block",
    fontSize: "0.9em",
    position: "absolute",
    top: "-15px",
    right: "0px",
    width: "100%",
  },
  votacaoIniciadaAction: {
    position: "relative",
    width: "180px",
    textAlign: "center",
    margin: "auto",
    marginTop: "10px",
  },
  votacaoFinalizadaAction: {
    textAlign: "center",
    margin: "auto",
  }
};

export default EditarVotacao;
