import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { Divider, Loader, Grid } from 'semantic-ui-react';
import ItemHistorico from './ItemHistorico';
import api from '../../../api';

const Historico = ({ match: { params } }) => {
  const [loading, setLoading] = useState(true);
  const [condominio, setCondominio] = useState({});
  const [pagamentos, setPagamentos] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(
    () => {
      const init = async () => {
        try {
          const condominioRes = await api.condominio.findSimpleById(params.id);
          const pagamentosRes = await api.pagamento.find(condominioRes._id);

          setCondominio(condominioRes);
          setPagamentos(pagamentosRes.pagamentos);
          setLoading(false);
        } catch (e) {
          setErrors(e.data.errors);
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

    const consultarStatus = async (hash) => {
        try {
            setLoading(true);
            const response = await api.pagamento.updateStatus(hash);
            setPagamentos(pagamentos.map(it => {
                let result = it;
                if (it.hash === response.hash) {
                    result = response;
                }
                return result;
            }));
            const condominioRes = await api.condominio.findSimpleById(params.id);
            setCondominio(condominioRes);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

  const getPagamentos = () =>
    pagamentos.length ? (
      <Grid columns={3} doubling stackable style={{ marginTop: 15 }}>
        {pagamentos.map((pagamento, i) => (
          <Grid.Column key={i}>
            <ItemHistorico pagamento={pagamento} consultarStatus={() => consultarStatus(pagamento.hash)} />
          </Grid.Column>
        ))}
      </Grid>
    ) : (
      <div className="empty-illustration-1">
        <img src="/images/illustrations/lupa-casa.svg" alt="Nenhuma Notificação Encontrada" />
      </div>
    );

  return (
    <div>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} />
        <Divider horizontal>Histórico de Pagamentos</Divider>
        {loading ? <Loader active inline="centered" style={{ marginTop: 50 }} /> : getPagamentos()}
      </div>
    </div>
  );
};

export default Historico;
