import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import {
  Input,
  Button,
  Form,
  Transition,
  Icon,
  Container,
  Message,
  Label,
  Popup,
  Radio,
  Modal,
} from 'semantic-ui-react';
import {
  findById,
  findByIdAndUpdate,
  reenviarEmailHash,
  gerarNovoHashUnidade,
} from '../../../actions/unidade';
import {
  findByDocumento as findResponsavelByDocumento,
  resetState as resetStateResponsavel,
} from '../../../actions/responsavel';
import * as FormValidator from '../../../utils/formValidator';
import Erro from '../../layouts/mensagens/Erro';
import Sucesso from '../../layouts/mensagens/Sucesso';
import MenuVertical from '../../layouts/MenuVertical';
import InputMask from 'react-text-mask';
import { formatDocumento } from '../../../utils/formatador';
import api from '../../../api';

class EditarUnidade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominio: {},
      unidade: props.unidade,
      errors: {},
      errorsResponsavel: {},
      sucesso: false,
      loading: true,
      loadingResponsavel: false,
      loadingEmail: false,
      responsavelCadastrado: true,
      responsavelBuscadoExiste: false,
      openModal: false,
      loadingHash: false,
      sucessoNovoHash: false,
    };
  }

  componentDidMount = async () => {
    await this.props.findById(this.props.match.params.id);

    try {
      const condominio = await api.condominio.findSimpleById(this.props.match.params.condominioId);
      this.props.resetStateResponsavel();
      this.setState({
        condominio,
        unidade: {
          ...this.props.unidade,
          responsavel: this.props.unidade.responsavel || this.props.responsavel,
          condominio: condominio._id,
        },
        loading: false,
      });
    } catch (e) {
      this.setState({
        errors: e.data.errors,
        loading: false,
      });
    }
  };

  onChange = (e) => {
    this.setState({
      unidade: {
        ...this.state.unidade,
        [e.target.name]: e.target.value,
      },
      errors: {},
      sucesso: false,
    });
  };

  onChangeTokenEmail = () => {
    this.setState({
      unidade: {
        ...this.state.unidade,
        enviarTokenEmail: !this.state.unidade.enviarTokenEmail,
      },
      errors: {},
    });
  };

  onChangeResponsavel = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'documento') {
      clearInterval(this.state.busca);

      const documento = value.replace(/\D/g, '');
      const tipoDocumento = documento.length > 11 ? 'CNPJ' : 'CPF';

      this.setState({
        unidade: {
          ...this.state.unidade,
          responsavel: {
            ...this.state.unidade.responsavel,
            documento,
            tipoDocumento,
            nome: '',
            email: '',
            celular: '',
          },
        },
        errors: {},
        errorsResponsavel: {},
        loadingResponsavel: false,
        responsavelBuscadoExiste: false,
      });

      if (documento.length === 11 || documento.length === 14) {
        this.findResponsavelByDocumento(documento, tipoDocumento);
      }
    } else {
      this.setState({
        unidade: {
          ...this.state.unidade,
          responsavel: {
            ...this.state.unidade.responsavel,
            [name]: value,
          },
        },
        errors: {},
        errorsResponsavel: {},
      });
    }
  };

  findResponsavelByDocumento = (documento, tipoDocumento) => {
    if (tipoDocumento === 'CPF' && !FormValidator.isCpf(documento)) {
      this.setState({ errorsResponsavel: { documento: 'CPF Inválido' } });
      return;
    }

    if (tipoDocumento === 'CNPJ' && !FormValidator.isCnpj(documento)) {
      this.setState({ errorsResponsavel: { documento: 'CNPJ Inválido' } });
      return;
    }

    this.setState({
      loadingResponsavel: true,
      busca: setTimeout(async () => {
        await this.props.findResponsavelByDocumento(documento);

        let newState = {
          errorsResponsavel: this.props.errorsResponsavel,
          errors: this.props.errors,
          loadingResponsavel: false,
        };

        if (
          FormValidator.objIsEmpty(this.props.errors) &&
          FormValidator.objIsEmpty(this.props.errorsResponsavel)
        ) {
          newState = {
            ...newState,
            unidade: {
              ...this.state.unidade,
              responsavel: this.props.responsavel,
            },
            responsavelCadastrado: true,
            responsavelBuscadoExiste: true,
          };
        } else {
          newState = {
            ...newState,
            responsavel: {
              nome: '',
              email: '',
              telefone: '',
              documento,
              tipoDocumento,
            },
            responsavelCadastrado: false,
          };
        }

        this.setState(newState);
      }, 1500),
    });
  };

  validate = (unidade) => {
    const errors = {};
    const { condominio } = this.state;

    if (FormValidator.isVazio(unidade.numero)) {
      errors.numero = 'Número/Identificador é obrigatório';
    }

    if (condominio && condominio.usarFracaoIdeal && !unidade.fracao) {
      errors.fracao = 'Fração é obrigatório';
    }

    if (
      FormValidator.isVazio(unidade.responsavel.documento.replace(/\D/g, ''))
    ) {
      errors.documento = 'Responsável obrigatório';
    } else if (
      !FormValidator.isCpf(unidade.responsavel.documento) &&
      !FormValidator.isCnpj(unidade.responsavel.documento)
    ) {
      errors.documento = 'Documento inválido';
    }

    if (!this.state.responsavelExists) {
      if (
        FormValidator.isVazio(unidade.responsavel.nome) ||
        !FormValidator.isNomeCompleto(unidade.responsavel.nome)
      ) {
        errors.nome = 'Digite o nome completo';
      }

      if (
        !FormValidator.isVazio(unidade.responsavel.email) &&
        !FormValidator.isEmail(unidade.responsavel.email)
      ) {
        errors.email = 'Insira um email válido';
      }
    }

    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.unidade);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.unidade._id,
        this.state.unidade
      );
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  disabledFields = () => {
    return !!(
      this.state.loadingResponsavel ||
      this.state.responsavelCadastrado ||
      !this.state.unidade.responsavel.documento ||
      (!FormValidator.isCpf(this.state.unidade.responsavel.documento) &&
        this.state.unidade.responsavel.tipoDocumento === 'CPF') ||
      (!FormValidator.isCnpj(this.state.unidade.responsavel.documento) &&
        this.state.unidade.responsavel.tipoDocumento === 'CNPJ')
    );
  };

  reenviarEmailHash = async (e) => {
    e.preventDefault();
    this.setState({ loadingEmail: true });
    await this.props.reenviarEmailHash(this.state.unidade._id);
    this.setState({
      loadingEmail: false,
      sucessoEmail: FormValidator.objIsEmpty(this.props.errors),
    });
  };

  podeEnviarWhatsApp = () =>
    this.props.unidade.responsavel &&
    !FormValidator.isVazio(this.props.unidade.responsavel.celular);

  getLinkWhatsApp = () => {
    const unidade = this.props.unidade;
    const condominio = this.state.condominio;

    const url = 'https://api.whatsapp.com/send';
    const text =
      `Olá ${unidade.responsavel.nome}, o administrador do ${condominio.nome} ` +
      `incluiu você à unidade votante ${unidade.bloco}-${unidade.numero} em nosso APP de votação. ` +
      `Para votar, utilize o TOKEN de acesso *${unidade.hash}* e baixe o APP Vote Assembleia das lojas ` +
      `Google Play: https://play.google.com/store/apps/details?id=br.com.voteassembleia.app OU ` +
      `Apple Store: https://apps.apple.com/br/app/vote-assembleia/id1544152783`;

    return `${url}?phone=55${unidade.responsavel.celular}&text=${text}`;
  };

  handleModal = e =>{
    e.preventDefault();
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  gerarNovoHashClickHandler = e => {
    e.preventDefault();
    this.setState({ loadingHash: true });
    this.props.gerarNovoHashUnidade({
          currentUnidadeId: this.state.unidade._id,
          responsavelId: this.state.unidade.responsavel._id,
          condominoId: this.state.condominio._id,
    }).then(()=>{
      this.setState({
        unidade:{
          ...this.state.unidade,
          hash: this.props.unidade.hash,
        },
        loadingHash: false,
        sucessoNovoHash: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
        openModal:false,
      });
    });
  };

  gerarNovoHash = () => {
    return <Modal
      trigger={
        <Popup
          content={'Clique aqui para gerar um novo token para a unidade votante.'}
          position='top center'
          size="large"
          trigger={
            <Button
              color="blue"
              size="medium"
              style={{ marginLeft: 15 }}
              disabled={this.state.sucessoNovoHash}
              loading={this.state.loadingHash}
              onClick={this.handleModal}
            >
              <Icon name="sync alternate" /> Gerar Novo Token
            </Button>
          }
        />
      }
      open={this.state.openModal}
      header='Confirmação'
      content={'Deseja gerar um novo token? Essa ação é irreversível e afetará todas as unidades votantes vinculadas ao responsável. Atenção! A(s) unidade(s) só terá(ão) acesso ao APP de votação com o novo token gerado!'}
      actions={[
        { key: 'close', content: 'Não', className: 'btn-ui-1 btn-ui-1-basic', onClick: this.handleModal},
        { key: 'done', content: 'Sim', className: 'btn-ui-1', onClick: this.gerarNovoHashClickHandler }
      ]}
    />
  };

  render() {
    const {
      unidade,
      errors,
      errorsResponsavel,
      loading,
      loadingEmail,
      sucessoEmail,
      loadingResponsavel,
      sucesso,
      responsavelCadastrado,
      responsavelBuscadoExiste,
      condominio,
      sucessoNovoHash,
    } = this.state;

    return (
      <div>
        {!!errors.global && <Redirect to='/' />}
        <MenuHorizontal />
        <div className='corpo-dashboard'>
          <MenuVertical condominio={condominio} />
          <Container className='ui-crud-container' fluid>
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation='fade up'
              transitionOnMount={true}
              visible={sucesso}
            >
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              >
                <Icon
                  name='check circle outline'
                  size='massive'
                  color='green'
                />
                <br />
                <h3>Unidade votante editada com sucesso</h3>
                <h3>
                  <Link to={`/condominios/${condominio._id}/unidades`}>
                    Voltar
                  </Link>
                </h3>
              </div>
            </Transition>
            {!sucesso && (
              <Form
                onSubmit={this.onSubmitEditarDados}
                loading={loading}
                autoComplete={'off'}
                size='large'
              >
                <div className='ui-crud-form'>
                  <h3>Dados da Unidade Votante</h3>
                  <Form.Field>
                    <label style={{ marginBottom: '10px' }}>
                      Não recebeu o token de acesso?
                    </label>
                    {sucessoEmail ? (
                      <Sucesso
                        style={{ fontSize: '1em' }}
                        text={'Email enviado com sucesso'}
                        size='big'
                      />
                    ) : (
                      <>
                        <Popup
                          trigger={
                            <Label
                              className='primary'
                              size='big'
                              basic
                              style={{ letterSpacing: '1px' }}
                            >
                              {unidade.hash}
                            </Label>
                          }
                          content={
                            <p>
                              <b>Token de acesso</b> para ser utilizado pelo
                              proprietário no aplicativo
                            </p>
                          }
                          size='large'
                        />
                        &nbsp;
                        {this.gerarNovoHash()}
                        <Button.Group>
                          <Button
                            className='btn-ui-1'
                            size='medium'
                            onClick={this.reenviarEmailHash}
                            disabled={
                              !unidade.responsavel ||
                              !unidade.responsavel.documento
                            }
                            loading={loadingEmail}
                          >
                            <Icon name='envelope' /> Reenviar email com token de
                            acesso
                          </Button>
                          {this.podeEnviarWhatsApp() && (
                            <>
                              <Button.Or text='ou' />
                              <Button
                                size='medium'
                                disabled={
                                  !unidade.responsavel ||
                                  !unidade.responsavel.documento
                                }
                                positive
                                as='a'
                                href={this.getLinkWhatsApp()}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Icon name='whatsapp' /> Enviar token de acesso
                                via WhatsApp
                              </Button>
                            </>
                          )}
                        </Button.Group>
                       {sucessoNovoHash &&
                        <Sucesso style={{ fontSize: '1em' }} text={'Novo token gerado com sucesso'} size='big' />}
                      </>
                    )}
                  </Form.Field>
                  <br />
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.bloco} width={3}>
                      <label htmlFor='bloco'>Bloco/Grupo</label>
                      <Input
                        name='bloco'
                        type='text'
                        placeholder='Bloco/Grupo'
                        onChange={this.onChange}
                        value={unidade.bloco}
                      />
                      <Erro text={errors.bloco} />
                    </Form.Field>
                    <Form.Field error={!!errors.numero} width={3}>
                      <label htmlFor='numero'>Número/Identificador</label>
                      <Input
                        name='numero'
                        type='text'
                        placeholder='Número/Identificador'
                        onChange={this.onChange}
                        value={unidade.numero}
                      />
                      <Erro text={errors.numero} />
                    </Form.Field>
                    {condominio.usarFracaoIdeal && (
                      <Form.Field error={!!errors.fracao} width={3}>
                        <label htmlFor='numero'>Fração Ideal</label>
                        <Input
                          name='fracao'
                          type='number'
                          min={0.0000000001}
                          step={0.0000000001}
                          max={1}
                          placeholder='Fração Ideal'
                          onChange={this.onChange}
                          value={unidade.fracao}
                        />
                        <Erro text={errors.fracao} />
                      </Form.Field>
                    )}
                    <Form.Field
                      error={!!errors.enviarTokenEmail}
                      inline
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Radio
                        toggle
                        label='Enviar Token para essa unidade votante ao final do cadastramento'
                        name='enviarTokenEmail'
                        checked={unidade.enviarTokenEmail}
                        onChange={this.onChangeTokenEmail}
                      />
                      <Erro text={errors.enviarTokenEmail} />
                    </Form.Field>
                  </Form.Group>
                  <h3 style={{ marginTop: '40px' }}>Dados do Proprietário/Associado</h3>
                  <Form.Field error={!!errors.nome} width={16}>
                    {!!errorsResponsavel.responsavel && (
                      <Message visible size='small'>
                        <Message.Content>
                          Não encontramos um proprietário com esse número de
                          documento. Complete os dados adicionais abaixo.
                        </Message.Content>
                      </Message>
                    )}
                    {!!loadingResponsavel && (
                      <Message size='small'>
                        <Message.Content>
                          <Icon name='spinner' loading /> Buscando
                          proprietário...
                        </Message.Content>
                      </Message>
                    )}
                    {!!responsavelBuscadoExiste && (
                      <Message size='small' className='primary'>
                        <Message.Content>
                          <Icon name='check' /> Proprietário encontrado
                        </Message.Content>
                      </Message>
                    )}
                  </Form.Field>
                  <Form.Group widths={2}>
                    <Form.Field error={!!errorsResponsavel.documento} width={6}>
                      <label htmlFor='documento'>
                        Documento do Proprietário/Associado ou Procurador
                      </label>
                      <Input
                        type='text'
                        children={
                          <InputMask
                            id='documento'
                            name='documento'
                            placeholder='CPF ou CNPJ'
                            onInput={this.onChangeResponsavel}
                            value={formatDocumento(
                              unidade.responsavel.documento
                            )}
                            mask={
                              unidade.responsavel.tipoDocumento === 'CPF'
                                ? [
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                  ]
                                : [
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '/',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                  ]
                            }
                            disabled={loadingResponsavel}
                            readOnly={loadingResponsavel}
                            guide={true}
                          />
                        }
                      />
                      <Erro text={errorsResponsavel.documento} />
                    </Form.Field>
                    <Form.Field error={!!errors.nome} width={10}>
                      <label htmlFor='nome'>Nome</label>
                      <Input
                        name='nome'
                        type='text'
                        placeholder='Nome'
                        onChange={this.onChangeResponsavel}
                        value={unidade.responsavel.nome}
                        loading={loadingResponsavel}
                        disabled={this.disabledFields()}
                      />
                      <Erro text={errors.nome} />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Transition
                      duration={{ show: 400, hide: 250 }}
                      animation='fade up'
                      visible={!responsavelCadastrado}
                    >
                      <Form.Field error={!!errors.celular} width={6}>
                        <label htmlFor='celular'>Celular</label>
                        <InputMask
                          type='text'
                          id='celular'
                          name='celular'
                          placeholder='(ddd) número'
                          mask={[
                            '(',
                            /[1-9]/,
                            /[1-9]/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide={true}
                          onChange={this.onChangeResponsavel}
                          value={unidade.responsavel.celular}
                          disabled={this.disabledFields()}
                        />
                        <Erro text={errors.celular} />
                      </Form.Field>
                    </Transition>
                    <Transition
                      duration={{ show: 400, hide: 250 }}
                      animation='fade up'
                      visible={!responsavelCadastrado}
                    >
                      <Form.Field error={!!errors.email} width={10}>
                        <label htmlFor='email'>Email</label>
                        <Input
                          name='email'
                          type='text'
                          placeholder='Email'
                          onChange={this.onChangeResponsavel}
                          value={unidade.responsavel.email}
                          disabled={this.disabledFields()}
                        />
                        <Erro text={errors.email} />
                      </Form.Field>
                    </Transition>
                  </Form.Group>
                  <div>
                    <Button
                      loading={loading}
                      disabled={loading}
                      className='btn-ui-1'
                      size='large'
                    >
                      <Icon name='save' /> Salvar Alterações
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unidade: state.unidade.unidade,
    responsavel: state.responsavel.responsavel,
    tiposDocumentos: state.responsavel.tiposDocumentos,
    errors: state.unidade.errors,
    errorsResponsavel: state.responsavel.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findByIdAndUpdate,
      findResponsavelByDocumento,
      resetStateResponsavel,
      reenviarEmailHash,
      gerarNovoHashUnidade
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarUnidade);
