import React, { useState } from "react";
import { Button, Card, Dimmer, Grid, Loader } from "semantic-ui-react";

const CardRelatorioUnidade = ({ onClick, label, disabled, children }) => {
    const styles = {
        card: {
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            height: "200px",
        }
    };

    const [loading, setLoading] = useState(false);

    const handleOnClick = async () => {
        if (!loading) {
            setLoading(true);
            await onClick();
            setLoading(false);
        }
    };

    return (
        <Grid.Column>
            <Card style={styles.card}>
                <Card.Description>
                    <h3 className="text-center" style={{ margin: "5px" }}>
                        {label}
                    </h3>
                </Card.Description>
                {children && (
                    <Card.Content extra>{children}</Card.Content>
                )}
                <div className="btn text-center" style={{ margin: "5px" }}>
                    {loading ? (
                        <Dimmer active inverted>
                            <Loader>Gerando Relatório...</Loader>
                        </Dimmer>
                    ) : (
                         <Button
                             onClick={handleOnClick}
                             color="blue"
                             size="large"
                             disabled={disabled}
                         >
                             Gerar
                         </Button>
                     )}
                </div>
            </Card>
        </Grid.Column>
    );
};

export default CardRelatorioUnidade;