import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Icon,
  Input,
  Label,
  Message,
  Popup,
  Segment,
  Statistic,
  Step,
  Tab,
  Table,
  Transition,
} from "semantic-ui-react";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from "../../layouts/MenuVertical";
import CondominioVencido from '../admin/CondominioVencido';
import Erro from "../../layouts/mensagens/Erro";
import * as FormValidator from "../../../utils/formValidator";
import api from "../../../api";

const initialStepState = {
  step1: { active: true, completed: false },
  step2: { active: false, completed: false },
};

const initialValidationResultState = {
  data: [],
  errors: [],
  rowCount: 0,
  invalidRowCount: 0,
  validRowCount: 0,
};

const URL_MODELO = 'https://votecondominio.s3.us-east-2.amazonaws.com/modelos/modelo+de+importac%CC%A7a%CC%83o.csv';

export default function ImportacaoCSV({ match }) {
  const [validationResult, setValidationResult] = useState(initialValidationResultState);
  const [globalError, setGlobalError] = useState(null);
  const [steps, setSteps] = useState(initialStepState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [enviarEmail, setEnviarEmail] = useState(false);
  const [invalidarHash, setInvalidarHash] = useState(false);
  const [condominio, setCondominio] = useState({});
  const inputFileRef = useRef();

  useEffect(() => {
    const init = async () => {
      try {
        setCondominio(await api.condominio.findSimpleById(match.params.condominioId));
      } catch(e) {
        //
      }
    };
    init();
  }, [match.params.condominioId]);

  async function onSubmit(e) {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);

    if (FormValidator.objIsEmpty(errors)) {
      setLoading(true);
      setSteps({
        ...initialStepState,
        step1: { active: false, completed: true },
        step2: { active: true, completed: false },
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("condominio", condominio._id);
      formData.append("enviarEmail", enviarEmail);
      formData.append("invalidarHash", invalidarHash);

      try {
        const response = await api.importacao.importar(formData);
        setValidationResult(response);
        setLoading(false);
        setGlobalError(null);
      } catch (e) {
        setLoading(false);
        if (e.data && e.data.message) {
          if (e.data.message.includes('duplicate key')) {
            setGlobalError('Existem registros com o e-mail duplicado verifique o arquivo importado.')
          } else {
            setGlobalError(e.data.message)
          }
        } else {
          setGlobalError('Ocorreu um erro inesperado, entre em contato com a Vote Assembleia.')
        }
      }
    }
  }

  function startAgain() {
    setLoading(false);
    setSteps(initialStepState);
    setValidationResult(initialValidationResultState);
    setGlobalError(null);
    setFile(null);
    clearInputFile();
  }

  function validate() {
    const errors = {};

    if (!file) {
      errors.mensagem = "Adicione um arquivo";
    }

    return errors;
  }

  function clearInputFile() {
    if (inputFileRef.current && inputFileRef.current.inputRef.current) {
      inputFileRef.current.inputRef.current.value = null;
    }
  }

  function onChangeFile(e) {
    if (e.target.files && e.target.files[0]) {
      const currentFile = e.target.files[0];
      const ext = currentFile.name.slice(currentFile.name.lastIndexOf("."));
      if (ext === ".csv") {
        if (FormValidator.sizeLimit(e.target.files[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
          setFile(e.target.files[0]);
          setErrors({});
        } else {
          setErrors({ mensagem: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb` });
          clearInputFile();
        }
      } else {
        setErrors({ mensagem: "Selecione um arquivo do tipo CSV" });
        clearInputFile();
      }
    }
  }

  function onChangeEnviarEmail() {
    setEnviarEmail(!enviarEmail);
  }

  function onChangeInvalidarHash(e) {
    setInvalidarHash(!invalidarHash);
  }

  function renderComplexTableRow(item, firstErr) {
    return (
      <>
        <Table.Row key={item.rowNumber}>
          <Table.Cell rowSpan={item.collumnsError.length}>
            <Label ribbon>{item.rowNumber}</Label>
          </Table.Cell>
          <Table.Cell>
            <strong>Campo: {firstErr.field}</strong>
            <p>{firstErr.message}</p>
            {firstErr.options && <span>{firstErr.options}</span>}
          </Table.Cell>
          <Table.Cell rowSpan={item.collumnsError.length}>
            {item.row}
          </Table.Cell>
        </Table.Row>
        {item.collumnsError
          .filter((err) => err.message !== firstErr.message)
          .map((err) => (
            <Table.Row key={`${item.rowNumber}-${err.field}`}>
              <Table.Cell>
                <strong>Campo: {err.field}</strong>
                <p>{err.message}</p>
                {err.options && <span>{err.options}</span>}
              </Table.Cell>
            </Table.Row>
          ))}
      </>
    );
  }

  function renderTableRow(item, err) {
    return (
      <Table.Row key={item.rowNumber}>
        <Table.Cell>
          <Label ribbon>{item.rowNumber}</Label>
        </Table.Cell>
        <Table.Cell>
          <strong>Campo: {err.field}</strong>
          <p>{err.message}</p>
          {err.options && <span>{err.options}</span>}
        </Table.Cell>
        <Table.Cell>{item.row}</Table.Cell>
      </Table.Row>
    );
  }

  function renderTabs() {
    const tabs = [];

    tabs.push({
      menuItem: { key: 'success', icon: 'check', content: 'Linhas importadas', color: 'green' },
      render: () => (
        <Tab.Pane attached={false} loading={loading} active={true}>
          <Table celled striped color="green">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Documento</Table.HeaderCell>
                <Table.HeaderCell>Responsável</Table.HeaderCell>
                <Table.HeaderCell>Unidade votante</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {validationResult.data.map(item => (
                <Table.Row key={`${item.documento}-${item.numero}`}>
                  <Table.Cell>
                    <Label ribbon>{item.documento}</Label>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>Nome: {item.nome}</strong>
                    <p>E-mail: {item.email}</p>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>Bloco/Nº: {item.bloco} / {item.numero}</strong>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Tab.Pane>
      ),
    });

    tabs.push({
      menuItem: { key: 'errors', icon: 'times', content: 'Linhas com erros', color: 'red' },
      render: () => (
        <Tab.Pane attached={false} loading={loading}>
          <Table celled striped color="red">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Linha</Table.HeaderCell>
                <Table.HeaderCell>Erros</Table.HeaderCell>
                <Table.HeaderCell>Dados</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {validationResult.errors.map(item => {
                const firstError = item.collumnsError[0];
                if (item.collumnsError.length > 1) {
                  return renderComplexTableRow(item, firstError);
                } else {
                  return renderTableRow(item, firstError);
                }
              })}
            </Table.Body>
          </Table>
        </Tab.Pane>
      ),
    });

    return <Tab menu={{ pointing: true, secondary: true }} panes={tabs} defaultActiveIndex={0} />;
  }

  return (
    <div>
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} />
        <CondominioVencido condominio={condominio}>
          <Segment
            padded
            style={{
              borderBottom: "2px solid #e83c6e",
              paddingBottom: "50px",
              marginTop: "0px",
              textAlign: 'initial'
            }}
          >
            <Step.Group widths={2}>
              <Step {...steps.step1}>
                <Icon name="file excel" />
                <Step.Content>
                  <Step.Title>Escolher Arquivo</Step.Title>
                </Step.Content>
              </Step>
              <Step {...steps.step2}>
                <Icon name="upload" />
                <Step.Content>
                  <Step.Title>Resultado da importação</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation="fade up"
              transitionOnMount={true}
              visible={steps.step1.active}
            >
              <Form
                onSubmit={onSubmit}
                loading={loading}
                autoComplete="off"
                size="large"
              >
                <div className="ui-crud-form">
                  <Segment padded>
                    <Form.Field error={!!errors.mensagem} inline>
                      <label style={{ display: "block", marginBottom: "5px" }}>
                        Arquivo (CSV)
                      </label>
                      <label
                        htmlFor="arquivo"
                        className="label-ui-arquivo"
                        style={{ width: "100%" }}
                      >
                        {file ? (
                          <span>
                            <Icon name="file excel outline"/> {file.name}
                          </span>
                        ) : (
                          <span>
                            <Icon name="paperclip"/> Selecionar arquivo
                          </span>
                        )}
                        <Input
                          type="file"
                          id="arquivo"
                          name="arquivo"
                          style={{ display: "none" }}
                          onChange={onChangeFile}
                          accept=".csv"
                          ref={inputFileRef}
                        />
                      </label>
                      <Erro text={errors.mensagem} />
                      <br />
                    </Form.Field>
                    <div className="ui two column centered grid">
                      <Form.Field width={8}>
                        <label htmlFor="enviarEmail">Não Enviar/Enviar E-mail</label>
                        <Popup
                            trigger={
                              <Checkbox
                                  toggle
                                  name="enviarEmail"
                                  onChange={onChangeEnviarEmail}
                                  checked={enviarEmail} style={{marginTop: "10px"}}
                              />
                            }
                            content='Clique aqui para informar se será enviado ou não o e-mail para o responsável da unidade votante.'
                            size='large'
                            position='top center'
                        />
                      </Form.Field>
                      <Form.Field width={8}>
                        <label htmlFor="invalidarHash">Não Gerar/Gerar Token</label>
                        <Popup
                            trigger={
                              <Checkbox
                                  toggle
                                  name="enviarEmail"
                                  onChange={onChangeInvalidarHash}
                                  checked={invalidarHash} style={{marginTop: "10px"}}
                              />
                            }
                            content='Clique aqui para informar se será regerado o token para as unidades votantes que serão atualizadas.'
                            size='large'
                            position='top center'
                        />
                      </Form.Field>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <br />
                      <Button
                        loading={loading}
                        disabled={loading}
                        className="btn-ui-1"
                        onClick={onSubmit}
                        size="large"
                        icon
                        labelPosition="right"
                      >
                        Avançar
                        <Icon name="right arrow" />
                      </Button>

                      {!enviarEmail && <Message info>
                        Você também pode utilizar a funcionalidade de enviar token para todas as unidades votantes no menu "Gerenciar Unidades Votantes".
                      </Message>}
                    </div>
                  </Segment>
                  <Segment padded>
                    <a
                      href={URL_MODELO}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="cloud download"/>
                      Clique aqui para baixar o modelo padrão do arquivo de importação
                    </a>
                  </Segment>
                </div>
              </Form>
            </Transition>
            <Transition
              animation={"fade up"}
              duration={{ show: 1200, hide: 0 }}
              transitionOnMount={true}
              visible={steps.step2.active}
            >
              <Segment padded>

                <Button className="btn-ui-1" onClick={startAgain}>
                  <Icon name="arrow left" /> Começar uma nova importação
                </Button>

                {globalError && (
                  <Card className="ui large negative button" size="large" style={{ width: '100%' }}>
                    <Card.Content style={{ textAlign: "center" }}>
                      <Card.Description>
                        <h3 style={{ color: '#fff' }}>
                          <Icon name="times circle outline" size="large" style={{ display: 'inline-block', verticalAlign: 'top' }} />
                          <span style={{ display: 'inline-block', marginTop: 3, marginLeft: 5 }}>
                            {globalError}
                          </span>
                        </h3>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                )}

                <Card.Group itemsPerRow={3}>
                  <Card>
                    <Card.Content textAlign="center">
                      <Statistic className="primary">
                        <Statistic.Value>
                          {validationResult.rowCount}
                        </Statistic.Value>
                        <Statistic.Label>Nº de linhas do arquivo</Statistic.Label>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content textAlign="center">
                      <Statistic color="green">
                        <Statistic.Value>
                          {validationResult.validRowCount}
                        </Statistic.Value>
                        <Statistic.Label>Nº de linhas válidas</Statistic.Label>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content textAlign="center">
                      <Statistic color="red">
                        <Statistic.Value>
                          {validationResult.invalidRowCount}
                        </Statistic.Value>
                        <Statistic.Label>Nº de linhas inválidas</Statistic.Label>
                      </Statistic>
                    </Card.Content>
                  </Card>
                </Card.Group>
                <Segment padded>
                  {renderTabs()}
                </Segment>
              </Segment>
            </Transition>
          </Segment>
        </CondominioVencido>
      </div>
    </div>
  );
}
