import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import MenuHorizontal from "../../layouts/MenuHorizontal";
import FormContato from '../../forms/FormContato';
// import Rodape from '../../layouts/Rodape';
// import { Link } from "react-router-dom";

class FaleConosco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: ""
    };
  }

  render() {
    return (
      <div>
        <MenuHorizontal {...this.props} backFunction={this.props.history.goBack} />
        <div className="ui-pagina-faleconosco">
          <div className="ui-pagina-login-gradient-img"></div>
          <Container className="text-center">
            <h1>Fale Conosco</h1>
            {/* <img src="/images/illustrations/fale-conosco.svg" alt="Assembleia" /> */}
          </Container>
          <Container className="ui-faleconosco-itens">
            <FormContato />
          </Container>
        </div>
      </div>
    )
  }
}

export default FaleConosco
