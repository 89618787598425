import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import FormCadastro from "../../forms/FormCadastroSindico";
import { cadastro } from "../../../actions/auth";
import { Card, Container, Transition } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import Rodape from "../../layouts/Rodape";

const Cadastro = (props) => {

  const submit = data =>
    props.cadastro(data).then((data) => data);

  return (
    <div>
      <MenuHorizontal activeItem={"Cadastro"} />
      <div className="ui-pagina-login">
        <div className="ui-pagina-login-gradient-img"></div>
        <Container>
          <Transition animation="fade down" duration={{ show: 1200, hide: 0 }} visible={true} transitionOnMount={true}>
            <Card fluid style={styles.card} className="card-auth">
              <Card.Content>
                <Card.Header style={styles.header}>
                  <img src="/images/logo-voteassembleia-com-escrita.png" alt="Vote Assembleia logo" height="64" style={{ marginBottom: "15px" }} />
                  <p className="ui-title">Cadastro do Administrador</p>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <FormCadastro submit={submit} />
              </Card.Content>
              <Card.Content>
                <div style={styles.cardLink}>
                  <Link to="/login">Já tenho uma conta</Link>
                </div>
              </Card.Content>
            </Card>
          </Transition>
        </Container>
        <p style={styles.copyright}>
          VOTE CONDOMÍNIO {new Date().getFullYear()}. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Link to="/politica-de-privacidade" className="rodape-link">Política de Privacidade</Link>
        </p>
        <p style={styles.copyright}>
          © 2017 - {new Date().getFullYear()} APP4ALL TECNOLOGIA DA INFORMAÇÃO LTDA.
        </p>
      </div>
      {/* <Rodape /> */}
    </div>
  );
}

Cadastro.propTypes = {
  cadastro: PropTypes.func.isRequired
}

const styles = {
  card: {
    padding: "5px 15px 0px 15px",
    margin: "auto",
    marginTop: "80px",
    marginBottom: "40px",
    overflow: "hidden",
    maxWidth: "600px"
  },
  header: {
    padding: "10px 10px",
    textAlign: "center"
  },
  cardLink: {
    fontSize: "13px",
    color: "#2185e0",
    cursor: "pointer",
    textAlign: "center"
  },
  copyright: {
    maxWidth: "400px",
    padding: "10px 20px",
    fontSize: "8pt",
    textAlign: "center",
    fontFamily: "Geomanist",
    margin: "auto",
    marginTop: "-25px",
    color: "white",
    zIndex: 1,
    position: "relative",
    marginBottom: "15px"
  }
}

export default connect(null, { cadastro })(Cadastro);
