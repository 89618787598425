import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from "../../layouts/MenuVertical";
import { Button, Divider, Form, Grid, Icon, Input, List, Loader, Message, Pagination, Responsive, Segment } from "semantic-ui-react";
import { findByIdAndBloquearVotacao } from "../../../actions/unidade";
import Unidade from "./Unidade";
import CondominioVencido from "../admin/CondominioVencido";
import moment from 'moment';
import api from '../../../api';
import ModalEnviarEmail from "./ModalEnviarEmail";
import { saveAs } from "file-saver";
import { base64toBlob } from "../../../utils/fileSaverHelper";
import CardRelatorioUnidade from "./CardRelatorioUnidade";
import InputMask from "react-text-mask";
import Erro from "../../layouts/mensagens/Erro";
import { getDataAtual } from "../../../utils/formatador";
import * as FormValidator from "../../../utils/formValidator";
import { isDataValida } from "../../../utils/formValidator";
import { DATA_PATTERN } from "../../../utils/patterns";

const Unidades = ({ match: { params } }) => {
  const dispatch = useDispatch();

  const usuario = useSelector(state => state.user.user);

  const [condominio, setCondominio] = useState({});
  const [unidades, setUnidades] = useState([]);
  const [habilitado, setHabilitado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
  const [modalBatchActionOpen, setModalBatchActionOpen] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [sincronizandoUnidades, setSincronizandoUnidades] = useState(false);
  const [isUnidadesVazia, setIsUnidadesVazia ] = useState(true);
  const [pagination, setPagination] = useState({
    dados: [],
    paginaAtual: 1,
    totalDePaginas: 0,
    registrosPorPagina: 10,
  });
  const [formulario, setFormulario] = useState({
    dataInicioFiltro: getDataAtual(1),
    dataFimFiltro: getDataAtual(0),
  });

  const interval = useRef();

  useEffect(
    () => {
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (sincronizandoUnidades) {
        interval.current = setInterval(checkSyncStatus, 5000);
      } else {
        if (!!interval.current) {
          clearInterval(interval.current);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sincronizandoUnidades]
  );

  useEffect(
    () => {
      if (condominio._id) {
        checkSyncStatus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [condominio]
  );

  useEffect(() => {
    setIsUnidadesVazia(!unidades || unidades.length === 0);
  }, [unidades]);

  const load = async () => {
    setLoading(true);
    try {
      const cond = await api.condominio.findSimpleById(params.id);
      setCondominio(cond);

      const isHabilitado = await api.unidade.validarQuantidade(cond._id).then(it => it.habilitado);
      setHabilitado(isHabilitado);

      const unidadesRes = await api.unidade.find(cond._id).then(it => it.unidades);
      setUnidades(unidadesRes);

      setRegistrosFiltrados(unidadesRes);
      updatePagination(unidadesRes, true);
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(false);
  };

  const isCondominioValido = () =>
    condominio.dataVencimento && moment(condominio.dataVencimento) >= moment();

  const atingiuLimite = () =>
    condominio.totalUnidades === condominio.countUnidades;

  const botaoBloquearClickHandler = async (e, data) => {
    setLoading(true);
    dispatch(findByIdAndBloquearVotacao(data.unidade._id));
    load();
  };

  const enviarEmailClickHandle = async(e) => {
    let idsUnidades = await unidades.map(it => it._id);
    setMessageVisible(true);
    setTimeout(() => {
      setMessageVisible(false);
    }, 3000);
    await api.unidade.enviarEmailUnidades({ condominio: condominio._id, unidadesSelecionadas: idsUnidades});
  };

  const modalBatchActionToggle = () => {
    setModalBatchActionOpen(!modalBatchActionOpen);
  };

  const isDemo = () => usuario.tipo === 'demo';

  const isCadastrarDesabilitado = () =>
    !isDemo() && (!isCondominioValido() || atingiuLimite());

  const containsInField = value => {
    const val = value.toLowerCase();
    const has = field => (field || '').toLowerCase().indexOf(val) !== -1;
    return it => has(it.bloco) || has(it.numero) || has(it.descricao) || (!!it.responsavel && has(it.responsavel.nome));
  };

  const onChangeSearch = e => {
    const dados = unidades.filter(containsInField(e.target.value));
    setRegistrosFiltrados(dados);
    updatePagination(dados);
  };

  const updatePagination = (dados, keepPage) =>
    setPagination(p => ({
      ...p,
      dados: Array.from(
        Array(p.registrosPorPagina),
        (_, i) => {
          let idx = keepPage ? i + ((p.paginaAtual - 1) * p.registrosPorPagina) : i;
          return dados[idx];
        }
      ).filter(it => it !== undefined),
      paginaAtual: keepPage ? p.paginaAtual : 1,
      totalDePaginas: Math.ceil(dados.length / p.registrosPorPagina),
    }));

  const changePage = (_, e) => {
    window.scrollTo(0, 0);
    setPagination(p => ({
      ...p,
      paginaAtual: e.activePage,
      dados: Array.from(
        Array(p.registrosPorPagina),
        (_, i) => registrosFiltrados[i + ((e.activePage - 1) * p.registrosPorPagina)])
          .filter(it => it !== undefined),
    }));
  };

  const syncUnidades = async () => {
    setSincronizandoUnidades(true);
    await api.unidade.sincronizar(condominio._id);
  };

  const checkSyncStatus = async () => {
    const status = await api.unidade.checkSincronizacao(condominio._id);
    if (status.finalizado) {
      if (sincronizandoUnidades) {
        setSincronizandoUnidades(false);
        load();
      }
    } else {
      if (!sincronizandoUnidades) {
        setSincronizandoUnidades(true);
      }
    }
  };

  const gerarRelatorioUnidades = async () => {
    const erros = validateRelatorioDeAcessos();
    setErrors(erros);

    if (Object.keys(erros).length === 0) {
      const response = await api.unidade.relatorioUnidades(condominio._id, {
        dataInicial: formulario.dataInicioFiltro,
        dataFinal: formulario.dataFimFiltro
      });

      saveAs(await base64toBlob(response, 'application/pdf'), 'relatorio-acessos-unidades.pdf');
    }
  };

  const validateRelatorioDeAcessos = () => {
    const erros = {};

    if (!isDataValida(formulario.dataInicioFiltro)) {
      erros.dataInicioFiltro = 'Data Inicial inválida.'
    }

    if (!isDataValida(formulario.dataFimFiltro)) {
      erros.dataFimFiltro = 'Data Final inválida.'
    }

    if (FormValidator.isVazio(formulario.dataInicioFiltro)) {
      erros.dataInicioFiltro = 'Data Inicial é obrigatória.';
    }

    if (FormValidator.isVazio(formulario.dataFimFiltro)) {
      erros.dataFimFiltro = 'Data Final é obrigatória.';
    }

    if (FormValidator.isDataMenor(formulario.dataFimFiltro, formulario.dataInicioFiltro)) {
      erros.dataFimFiltro = 'Data Final não pode ser inferior a Data Inicial.'
    }

    return erros;
  };

  const gerarRelatorioLeituraEmail = async () => {
    const response = await api.unidade.relatorioLeituraEmail(condominio._id);
    saveAs(await base64toBlob(response, 'application/pdf'), 'relatorio-leitura-email.pdf');
  };

  const gerarRelatorioListagemUnidades = async () => {
    const response = await api.unidade.relatorioListagemUnidades(condominio._id);
    saveAs(await base64toBlob(response, 'application/pdf'), 'relatorio-listagem-unidades.pdf');
  };

  const getContent = () =>
    pagination.dados.length > 0 ? (
      <>
        <List verticalAlign="middle">
          {pagination.dados.map((unidade, i) => (
            <Unidade unidade={unidade} key={unidade._id} bloquearHandle={botaoBloquearClickHandler} />
          ))}
        </List>
        {pagination.totalDePaginas > 1 && (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Pagination activePage={pagination.paginaAtual} totalPages={pagination.totalDePaginas} onPageChange={changePage} />
          </div>
        )}
      </>
    ) : (
      <div className="empty-illustration-1">
        <img
          src="/images/illustrations/lupa-casa.svg"
          alt="Nenhuma Unidade Votante Encontrada"
        />
      </div>
    );

  const onChange = ({ target: { name, value } }) => changeFormulario(name, value);
  const changeFormulario = (name, value) => setFormulario(p => ({ ...p, [name]: value }));

  const isAtivo = () => condominio.dataVencimento && moment(condominio.dataVencimento) >= moment();

  return (
    <div>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} />
        {!loading && (
          <CondominioVencido condominio={condominio}>
            {(isDemo() && !habilitado) || (isCondominioValido() && atingiuLimite()) ? (
              <div style={{ padding: "20px" }}>
                <h4 style={{ textAlign: "center" }}>Todas as {isDemo() ? condominio.countUnidades : condominio.totalUnidades} unidades votantes já foram criadas</h4>
              </div>
            ) : (
              <Button
                className="btn-ui-1"
                as={Link}
                to={`/condominios/${condominio._id}/unidades/cadastrar`}
                size="large"
                disabled={isCadastrarDesabilitado()}
              >
                <Icon name="plus" /> Cadastrar Unidade Votante
              </Button>
            )}
            <Button
              color="green"
              size="large"
              style={{ marginLeft: 15 }}
              onClick={modalBatchActionToggle}
              disabled={isUnidadesVazia}
            >
              <Icon name="mail outline" /> Enviar token para todas as unidades votantes
            </Button>
            {usuario.integracoes.length > 0 && (
              <Button
                color="brown"
                size="large"
                style={{ marginLeft: 15 }}
                onClick={syncUnidades}
                disabled={sincronizandoUnidades}
              >
                <Icon name="sync" loading={sincronizandoUnidades} />
                {sincronizandoUnidades ? 'Em Andamento' : 'Sincronizar'}
              </Button>
            )}
          </CondominioVencido>
        )}
        <Segment textAlign="center" basic>
          <Responsive minWidth={800}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Buscar Unidade Votante (Bloco/Grupo, Número/Identificador, Descrição e Proprietário)"
              style={{ width: 400 }}
              onChange={onChangeSearch}
            />
          </Responsive>
          <Responsive maxWidth={799}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Buscar Unidade Votante"
              onChange={onChangeSearch}
            />
          </Responsive>
        </Segment>
        {loading || !(isDemo() || isAtivo()) ? null : (
            <>
              <Divider horizontal>Relatórios</Divider>
              <Grid stackable columns={3} doubling>
                <CardRelatorioUnidade
                    label="Relatório de Acessos"
                    onClick={gerarRelatorioUnidades}
                    disabled={isUnidadesVazia}
                >
                  <Form>
                    <Form.Group widths={2}>
                      <Form.Field error={!!errors.dataInicioFiltro}>
                        <label htmlFor="dataInicioFiltro">Data inicial</label>
                        <InputMask
                            type="text"
                            id="dataInicioFiltro"
                            name="dataInicioFiltro"
                            mask={DATA_PATTERN}
                            guide={true}
                            placeholder="dia/mês/ano"
                            onChange={onChange}
                            value={formulario.dataInicioFiltro}
                        />
                        <Erro text={errors.dataInicioFiltro}/>
                      </Form.Field>
                      <Form.Field error={!!errors.dataFimFiltro}>
                        <label htmlFor="dataFimFiltro">Data final</label>
                        <InputMask
                            type="text"
                            id="dataFimFiltro"
                            name="dataFimFiltro"
                            mask={DATA_PATTERN}
                            guide={true}
                            placeholder="dia/mês/ano"
                            onChange={onChange}
                            value={formulario.dataFimFiltro}
                        />
                        <Erro text={errors.dataFimFiltro}/>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </CardRelatorioUnidade>
                <CardRelatorioUnidade
                    label="Relatório de Leitura de E-mail"
                    onClick={gerarRelatorioLeituraEmail}
                    disabled={isUnidadesVazia}
                />
                <CardRelatorioUnidade
                    label="Relatório de Listagem Unidades"
                    onClick={gerarRelatorioListagemUnidades}
                    disabled={isUnidadesVazia}
                />
              </Grid>
            </>
        )}
        <Divider horizontal>Unidades Votantes</Divider>
        <ModalEnviarEmail
          modalOpen={modalBatchActionOpen}
          btnConfirmAction={enviarEmailClickHandle}
          toggleFunction={modalBatchActionToggle}
          message="Deseja realmente enviar o token para todas as unidades votantes?"
        />
        {messageVisible && (
          <Message positive>
            <Message.Header>Tudo ok!</Message.Header>
            <p>
              Os e-mails já estão a caminho.
            </p>
          </Message>
        )}
        {loading ? <Loader active inline="centered" style={{ marginTop: "50px" }} /> : getContent()}
      </div>
    </div>
  );
}

export default Unidades;
