import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Menu,
  Icon,
  Image,
  Placeholder,
  Popup,
  Button
} from "semantic-ui-react";
import moment from 'moment';

const MenuVertical = ({ loading, condominio }) => {
  const usuario = useSelector(state => state.user.user);

  const quantidadeUnidadesDisponiveis = () =>
    condominio.totalUnidades - ((condominio.unidades && condominio.unidades.length) || condominio.countUnidades);

  const atingiuLimite = () =>
    ((condominio.unidades && condominio.unidades.length) || condominio.countUnidades) === condominio.totalUnidades || condominio.totalUnidades === 0;

  const dataVencimento = () => {
    const style = { ...styles.infoCondominioH3 };
    let msg = null;

    if (usuario && usuario.tipo === 'demo') {
      msg = 'Demo';
      style.color = '#e83c6e';
    } else if (condominio.dataVencimento) {
      msg = moment(condominio.dataVencimento).format('DD/MM/YYYY');
      if (moment(condominio.dataVencimento) < moment()) {
        style.color = '#f00';
      }
    } else {
      msg = 'Pendente';
      style.color = '#f00';
    }

    return <h3 style={style}>{msg}</h3>
  }

  return (
    <div>
      <nav className="menu-vertical">
        {loading ? (
          <Menu vertical style={styles.menu}>
            <div style={{ ...styles.menuLink, marginTop: "20px" }}>
              <Menu.Item style={styles.menuItem}>
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
                <Placeholder>
                  <Placeholder style={{ height: 150, width: 80 }}>
                    <Placeholder.Image />
                  </Placeholder>
                  {Array.from(Array(7), (_, index) => (
                    <Placeholder.Header key={index}>
                      <Placeholder.Line />
                    </Placeholder.Header>
                  ))}
                </Placeholder>
              </Menu.Item>
            </div>
          </Menu>
        ) : (
            <Menu vertical style={styles.menu}>
              <Link to="/" style={styles.menuLink}>
                <Menu.Item style={styles.menuItem} as="span">
                  Selecionar Condomínio / Sociedade / Associação <Icon name="building" />
                </Menu.Item>
              </Link>
              {!!condominio && !!condominio.nome ? (
                <Menu.Item style={styles.menuInfo} as="span" name="home">
                  <div className="text-center" style={styles.itemMenuInfo}>
                    <h3>
                      <b>{condominio.nome}</b>
                    </h3>
                    <div style={styles.infoCondominio}>
                      <div>
                        <h3 style={styles.infoCondominioH3}>{(condominio.unidades && condominio.unidades.length) || condominio.countUnidades}</h3>
                        <p>Unidade{((condominio.unidades && condominio.unidades.length) || condominio.countUnidades) !== 1 && 's'} Votante{((condominio.unidades && condominio.unidades.length) || condominio.countUnidades) !== 1 && 's'}</p>
                      </div>
                      <div>
                        {dataVencimento()}
                        <p>Vencimento</p>
                      </div>
                    </div>
                    <Image
                      src="/images/illustrations/unidades.svg"
                      style={{ width: "70%", margin: "auto" }}
                    />
                    {atingiuLimite() ? (
                      <div style={{ paddingTop: "20px" }}>
                        <Button
                          className="btn-ui-1"
                          fluid
                          style={{ padding: "15px" }}
                          as={Link}
                          to={`/condominios/${condominio._id}/comprar`}
                        >
                          Comprar Unidades Votantes
                        </Button>
                      </div>
                    ) : (
                      <Popup
                        trigger={
                          <p className="ui-destaque-creditos">
                            <b>{quantidadeUnidadesDisponiveis()} / {condominio.totalUnidades}</b>
                            <br />
                            unidades votantes disponíveis
                          </p>
                        }
                        position="top center"
                        size="large"
                      >
                        <p style={{ textAlign: "center" }}>
                          Você pode criar mais{" "}
                          <b>{quantidadeUnidadesDisponiveis()}</b> unidade{quantidadeUnidadesDisponiveis() !== 1 && 's'} votante{quantidadeUnidadesDisponiveis() !== 1 && 's'}
                        </p>
                      </Popup>
                    )}
                  </div>
                </Menu.Item>
              ) : (
                  false
                )}
              {!!condominio && (
                <>
                  <Link to={`/condominios/${condominio._id}`} style={styles.menuLink}>
                    <Menu.Item style={styles.menuItem} as="span">
                      Visão Geral <Icon name="building" />
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/unidades`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Gerenciar Unidades Votantes<Icon name="home" />
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/responsaveis`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Gerenciar Proprietários <Icon name="group" />
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/votacoes`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Gerenciar Votações <Icon name="calendar check" />
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/notificacoes`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Gerenciar Convocação Digital / Notificações <Icon name="alarm" />
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/importacao`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Importar Arquivo CSV <Icon name="file excel"/>
                    </Menu.Item>
                  </Link>
                  <Link
                    to={`/condominios/${condominio._id}/historico`}
                    style={styles.menuLink}
                  >
                    <Menu.Item style={styles.menuItem} as="span">
                      Histórico de Pagamentos <Icon name="clipboard list" />
                    </Menu.Item>
                  </Link>
                  <Link to={`/condominios/${condominio._id}/comprar`} style={styles.menuLink}>
                    <Menu.Item style={styles.lastMenuItem} as="span">
                      <b>Comprar/Renovar Unidades Votantes</b>
                      <Icon name="credit card" />
                    </Menu.Item>
                  </Link>
                </>
              )}
            </Menu>
          )}
      </nav>
    </div>
  );
};

const styles = {
  infos: {
    marginBottom: "10px"
  },
  itemMenuInfo: {
    padding: "10px"
  },
  ulVertical: {
    marginTop: "8px"
  },
  menu: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    borderRadius: "0",
    borderRight: "none"
  },
  menuMobile: {
    borderRadius: "0",
    height: "100%",
    backgroundColor: "#000000"
  },
  menuInfo: {
    borderRadius: "0px",
    padding: "25px 20px",
    color: "#000",
    width: "100%",
    border: "none",
    backgroundColor: "#fff",
    textAlign: "center",
    marginTop: "-1px"
  },
  menuItemMobile: {
    borderRadius: "0px",
    padding: "20px",
    color: "#ffff",
    width: "100%",
    border: "none",
    textAlign: "center !important"
  },
  menuItemSaldo: {
    borderRadius: "0px",
    padding: "20px",
    color: "#2185d0",
    position: "absolute",
    bottom: "200px",
    left: "500px",
    width: "100%",
    zIndex: "10000 !important"
  },
  iconMobile: {
    margin: "auto",
    fontSize: "22px",
    marginTop: "-8px",
    marginBottom: "-8px"
  },
  menuLink: {
    textDecoration: "none",
    padding: "0px"
  },
  infoCondominio: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 20
  },
  infoCondominioH3: {
    margin: 0
  },
  lastMenuItem: {
    marginBottom: "70px",
  },
};

export default MenuVertical;
