import React, { useState, useEffect } from 'react';
import { Transition, Segment, Form, Message, Icon, Popup, Input, Button } from 'semantic-ui-react';
import * as FormValidator from '../../../utils/formValidator';
import Erro from '../../layouts/mensagens/Erro';
import InputMask from 'react-text-mask';
import api from '../../../api';
import { formatDocumento } from "../../../utils/formatador";
import InfinitySelect from '../../layouts/InfinitySelect';

const EditarVotacaoSobre = ({ visible, loadingVotacao, condominio, votacao, votacaoPossuiVoto, updateVotacao }) => {
  const [responsaveis, setResponsaveis] = useState([]);
  const [convocacao, setConvocacao] = useState(null);
  const [formState, setFormState] = useState({ presidente: '0', secretario: '0', ...votacao });
  const [loading, setLoading] = useState({ votacao: loadingVotacao, responsavel: true, documento: false });
  const [sucesso, setSucesso] = useState({ votacao: false, convocacao: false });
  const [errors, setErrors] = useState({});

  useEffect(
    () => {
      if (condominio && condominio._id) {
        loadResponsaveis();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [condominio]
  );

  useEffect(() => {
    setLoading(p => ({ ...p, votacao: loadingVotacao }));
    setFormState(p => ({
      ...p,
      ...votacao,
      presidente: votacao.presidente || '0',
      secretario: votacao.secretario || '0',
    }));
  }, [loadingVotacao, votacao]);

  const loadResponsaveis = async () => {
    try {
      setResponsaveis(await api.responsavel.find(condominio._id).then(it => it.responsaveis));
      setLoading(p => ({ ...p, responsavel: false }));
    } catch (e) {
      setErrors(e.data.errors);
    }
  };

  const closeMessage = (_, { name }) => setSucesso(p => ({ ...p, [name]: false }));

  const existeDoc = () => !!formState.documentoConvocacao && formState.documentoConvocacao.url;

  const getDocConvocacaoLabel = () =>
    existeDoc() ? 'Alterar documento atual' : 'Selecionar documento PDF';

  const getDocConvocacaoLink = () =>
    existeDoc() ? (
      <a href={formState.documentoConvocacao.url} target="_blank" rel="noopener noreferrer">Ver documento atual</a>
    ) : 'Nenhum documento anexado';

  const getResponsaveis = label => [
    { key: '0', value: '0', text: `Selecione o ${label}` },
    ...responsaveis.map(it => ({
      key: it._id,
      value: it._id,
      text: `${it.nome} - ${formatDocumento(it.documento)}`
    }))
  ];

  const onChangeFile = e => {
    if (e.target.files && e.target.files[0] && e.target.files[0].type === 'application/pdf') {
      if (FormValidator.sizeLimit(e.target.files[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
        setConvocacao(e.target.files[0]);
        setErrors({});
      } else {
        setErrors({ documentoConvocacao: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb` });
      }
    }
  };

  const changeFormState = (name, value) => {
    setFormState(p => ({ ...p, [name]: value }));
    setErrors({});
  }

  const onChange = e => changeFormState(e.target.name, e.target.value);
  const onChangeSelect = (_, { name, value }) => changeFormState(name, value);

  const onSubmitDocumento = async () => {
    if (convocacao) {
      setLoading(p => ({ ...p, documento: true }));

      const formData = new FormData();

      formData.append('field', 'documentoConvocacao');
      formData.append('condominio', condominio._id);
      formData.append('arquivo', convocacao);

      try {
        const documentoConvocacao = await api.votacao.atualizarDocumento(votacao._id, formData);

        setFormState(p => ({ ...p, documentoConvocacao }));
        setLoading(p => ({ ...p, documento: false }));
        setSucesso(p => ({ ...p, convocacao: true }));
        setConvocacao(null);
      } catch (e) {
        setErrors(e.data.errors);
      }
    } else {
      setErrors({ documentoConvocacao: 'Selecione um arquivo PDF' });
    }
  };

  const onSubmitDados = async e => {
    e.preventDefault();

    const errs = validate();
    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      setLoading(p => ({ ...p, votacao: true }));

      try {
        const result = await api.votacao.updateSobre(votacao._id, { condominio: condominio._id, ...formState });
        updateVotacao(result);
        setLoading(p => ({ ...p, votacao: false }));
        setSucesso(p => ({ ...p, votacao: true }));
      } catch (e) {
        setErrors(e.data.errors);
      }
    }
  };

  const validate = () => {
    const err = {};

    if (FormValidator.isVazio(formState.titulo)) {
      err.titulo = "Titulo é obrigatório";
    }

    if (FormValidator.isVazio(formState.dataInicioReuniao)) {
      err.dataInicioReuniao = "Data início é obrigatório";
    } else if (!FormValidator.isDataVotacao(formState.dataInicioReuniao) && !formState._id) {
      err.dataInicioReuniao = "Digite uma data maior ou igual a data atual";
    }

    if (FormValidator.isVazio(formState.dataFinalReuniao)) {
      err.dataFinalReuniao = "Data limite é obrigatório";
    } else if (!FormValidator.isDataVotacao(formState.dataFinalReuniao) && !formState._id) {
      err.dataFinalReuniao = "Digite uma data maior ou igual a data atual";
    }

    if (!FormValidator.isVazio(formState.urlTransmissao) && !FormValidator.isUrl(formState.urlTransmissao)) {
      err.urlTransmissao = "Digite uma url válida";
    }

    return err;
  };

  return (
    <Transition
      animation="fade up"
      duration={{ show: 700, hide: 0 }}
      visible={visible}
      transitionOnMount={true}
    >
      <div>
        {votacaoPossuiVoto && (
          <Message
            style={{ marginTop: 20 }}
            color="primary"
            header="Atenção"
            content="Esta votação já possui votos! Redobre a atenção ao atualizar os dados."
          />
        )}
        <Segment padded>
          <Form loading={loading.votacao || loading.responsavel} autoComplete="off" size="large">
            <Transition animation="fade up" duration={1000} visible={sucesso.votacao}>
              <Message positive size="tiny" name="votacao" onDismiss={closeMessage}>
                <Icon name='check' /> Dados alterados com sucesso!
              </Message>
            </Transition>
            <Segment>
              <div>
                <Form.Field error={!!errors.documentoConvocacao} inline>
                  <label style={{ display: 'block', marginBottom: 5 }}>Documento de Convocação</label>
                  <div style={{ position: 'relative' }}>
                    <Transition animation="fade up" duration={{ show: 1000, hide: 400 }} visible={sucesso.convocacao}>
                      <Message positive size="tiny" name="convocacao" onDismiss={closeMessage}>
                        <Icon name='check' /> Documento alterado com sucesso!
                      </Message>
                    </Transition>
                    <div style={{ display: "inline-block" }}>
                      <Popup
                        trigger={
                          <label htmlFor="documentoConvocacao" className="label-ui-arquivo">
                            {convocacao ? (
                              <span>
                                <Icon name="file pdf outline" /> {convocacao.name}
                              </span>
                            ) : (
                              <span><Icon name="paperclip" /> {getDocConvocacaoLabel()}</span>
                            )}
                            <Input
                              type="file"
                              id="documentoConvocacao"
                              name="documentoConvocacao"
                              style={{ display: "none" }}
                              onChange={onChangeFile}
                              accept="application/pdf"
                            />
                          </label>
                        }
                        content="Selecione um documento PDF"
                      />
                      <br />
                      {getDocConvocacaoLink()}
                    </div>
                    <div className="div-ui-acao">
                      {!!convocacao && (
                        <Button
                          disabled={loading.documento || loading.votacao || loading.responsavel}
                          loading={loading.documento}
                          size="small"
                          className="btn-ui-1"
                          onClick={onSubmitDocumento}
                        >
                          Confirmar Alteração
                        </Button>
                      )}
                    </div>
                  </div>
                  <Erro text={errors.documentoConvocacao} />
                  <br />
                </Form.Field>
              </div>
              <Form.Group widths={2}>
                <Form.Field error={!!errors.titulo}>
                  <label htmlFor="titulo">Título</label>
                  <Input
                    name="titulo"
                    type="text"
                    placeholder="Título"
                    onChange={onChange}
                    value={formState.titulo}
                  />
                  <Erro text={errors.titulo} />
                </Form.Field>
                <Form.Field error={!!errors.local}>
                  <label htmlFor="local">Local</label>
                  <Input
                    type="text"
                    id="local"
                    placeholder="Local"
                    name="local"
                    value={formState.local}
                    onChange={onChange}
                  />
                  <Erro text={errors.local} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={4}>
                <Form.Field error={!!errors.dataInicioReuniao}>
                  <label htmlFor="dataInicioReuniao">Data início da reunião</label>
                  <InputMask
                    type="text"
                    id="dataInicioReuniao"
                    name="dataInicioReuniao"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={true}
                    placeholder="dia/mês/ano"
                    value={formState.dataInicioReuniao}
                    onChange={onChange}
                  />
                  <Erro text={errors.dataInicioReuniao} />
                </Form.Field>
                <Form.Field error={!!errors.horarioReuniao}>
                  <label htmlFor="horarioReuniao">Horário da reunião</label>
                  <InputMask
                    type="text"
                    id="horarioReuniao"
                    name="horarioReuniao"
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    guide={true}
                    placeholder="hh:mm"
                    value={formState.horarioReuniao}
                    onChange={onChange}
                  />
                  <Erro text={errors.horarioReuniao} />
                </Form.Field>
                <Form.Field error={!!errors.dataFinalReuniao}>
                  <label htmlFor="dataFinalReuniao">Data limite para votações</label>
                  <InputMask
                    type="text"
                    id="dataFinalReuniao"
                    name="dataFinalReuniao"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={true}
                    placeholder="dia/mês/ano"
                    value={formState.dataFinalReuniao}
                    onChange={onChange}
                  />
                  <Erro text={errors.dataFinalReuniao} />
                </Form.Field>
                <Form.Field error={!!errors.horarioFinalReuniao}>
                  <label htmlFor="horarioFinalReuniao">Horário limite para votações</label>
                  <InputMask
                    type="text"
                    id="horarioFinalReuniao"
                    name="horarioFinalReuniao"
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    guide={true}
                    placeholder="hh:mm"
                    value={formState.horarioFinalReuniao}
                    onChange={onChange}
                  />
                  <Erro text={errors.horarioFinalReuniao} />
                </Form.Field>
              </Form.Group>
              <Form.Field error={!!errors.urlTransmissao}>
                <label htmlFor="urlTransmissao">Link de acesso ou transmissão</label>
                <Input
                  name="urlTransmissao"
                  type="text"
                  placeholder="Link de acesso ou transmissão Zoom, Youtube, Meet, Teams, Skype, entre outros."
                  onChange={onChange}
                  value={formState.urlTransmissao}
                />
                <Erro text={errors.urlTransmissao} />
              </Form.Field>
            </Segment>
            <Segment>
              <Form.Group widths={2}>
                <Form.Field>
                  <InfinitySelect
                    fluid
                    label='Presidente'
                    name="presidente"
                    options={getResponsaveis('presidente')}
                    onChange={onChangeSelect}
                    value={formState.presidente}
                  />
                </Form.Field>
                <Form.Field>
                  <InfinitySelect
                    fluid
                    label='Secretário'
                    name="secretario"
                    options={getResponsaveis('secretário')}
                    onChange={onChangeSelect}
                    value={formState.secretario}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
            <div style={{ textAlign: "center" }}>
              <br />
              <Button
                loading={loading.votacao || loading.responsavel}
                disabled={loading.votacao || loading.responsavel}
                className="btn-ui-1"
                onClick={onSubmitDados}
                size="large"
              >
                <Icon name="save" /> Salvar Alterações
              </Button>
            </div>
          </Form>
        </Segment>
      </div>
    </Transition>
  );
};

export default EditarVotacaoSobre;
