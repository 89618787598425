import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Container, Input, Modal, Popup } from 'semantic-ui-react';
import api from '../../../api';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { MessageList } from 'react-chat-elements';
import { connect, disconnect, socket } from '../../../socket';
import { useSelector } from 'react-redux';
import ModerateButton from './ModerateButton';
import moment from 'moment';

import 'react-chat-elements/dist/main.css';
import './QuestionChat.css';

const QuestionChat = ({ match: { params } }) => {
  const history = useHistory();
  const usuario = useSelector(state => state.user.user);

  const [condominio, setCondominio] = useState({});
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [nomeUsuario, setNomeUsuario] = useState(usuario.nome);
  const [hashUsuario, setHashUsuario] = useState(null);

  const [mensagem, setMensagem] = useState('');
  const [listaMensagens, setListaMensagem] = useState([]);

  useState(() => {
    const init = async () => {
      connect();

      let hash = null;
      const chatData = window.localStorage.getItem('sindico-chat');

      if (!!chatData) {
        const data = JSON.parse(chatData);
        const chat = data.find(it => it.pergunta === params.idPergunta);
        if (chat) {
          hash = chat.hash;
          setHashUsuario(chat.hash);
          setNomeUsuario(chat.nome);
        }
      }

      socket.on('messages', data => {
        data.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
        setListaMensagem(
          data.map(it => {
            const isUsuarioAtual = it.user.isSindico && it.user.hash === (hashUsuario || hash);
            const style = { textDecoration: it.moderado ? 'line-through' : 'none' };
            return {
              type: 'text',
              notch: false,
              position: isUsuarioAtual ? 'right' : 'left',
              title: (
                <ModerateButton
                  message={it}
                  position={isUsuarioAtual ? 'right' : 'left'}
                  moderate={moderate}
                />
              ),
              text: <span style={style}>{it.text}</span>,
              dateString: moment(it.createdAt).format('HH:mm'),
            };
          })
        );
      });

      socket.on('message', message => {
        setListaMensagem(p => [
          ...p,
          {
            type: 'text',
            notch: false,
            position: 'left',
            title: message.user.nome,
            text: message.text,
            dateString: moment(message.createdAt).format('HH:mm'),
          }
        ])
      });

      socket.emit('joinChat', { pergunta: params.idPergunta });

      try {
        setCondominio(await api.condominio.findSimpleById(params.condominioId));
      } catch(e) {
        setErrors(e.data.errors);
      }
    };

    init();

    return () => {
      disconnect();
    };
  }, []);

  const genHashChat = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const onChangeNomeUsuario = ({ target: { value } }) => setNomeUsuario(value);
  const onChangeMensagem = ({ target: { value } }) => setMensagem(value);

  const onKeyUp = e => {
    if ((e.keyCode || e.which) === 13) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (mensagem) {
      socket.emit('sendMessageSindico', {
        idSindico: usuario._id,
        pergunta: params.idPergunta,
        nomeUsuario,
        hashUsuario,
        mensagem,
      });
      setMensagem('');
    }
  };

  const confirmModal = () => {
    if (nomeUsuario) {
      let data = [];

      const chatData = window.localStorage.getItem('sindico-chat');
      if (!!chatData) {
        data = JSON.parse(chatData);
      }

      if (hashUsuario) {
        data = data.map(it => {
          if (it.pergunta === params.idPergunta && it.hash === hashUsuario) {
            it.nome = nomeUsuario;
          }
          return it;
        });
      } else {
        const hash = genHashChat();
        data.push({ pergunta: params.idPergunta, nome: nomeUsuario, hash });
        setHashUsuario(hash);
      }

      window.localStorage.setItem('sindico-chat', JSON.stringify(data));
    }
  };

  const moderate = message => {
    socket.emit('moderateMessage', { _id: message._id, moderado: message.moderado });
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard-mini">
        <MenuVertical condominio={condominio} />
        <Container style={{ paddingTop: 10 }}>
          <div className="ui-crud-form">
            <Button className="btn-ui-1 btn-ui-1-basic" style={{ marginBottom: 5 }} onClick={goBack}>Voltar</Button>
            <div className="chat-box">
              <Modal
                size="tiny"
                onClose={closeModal}
                open={modalOpen}
                header="Informe o nome que será exibido nas mensagens"
                content={
                  <div className="input-nome-sindico">
                    <Input
                      name="nomeUsuario"
                      type="text"
                      placeholder="Informe o nome"
                      value={nomeUsuario}
                      onChange={onChangeNomeUsuario}
                      autoFocus={true}
                      fluid={true}
                    />
                  </div>
                }
                actions={[
                  { key: 'cancel', onClick: closeModal, content: 'Cancelar' },
                  { key: 'confirm', onClick: confirmModal, content: 'Interagir', positive: true }
                ]}
              />
              <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight="100%"
                dataSource={listaMensagens}
              />
              <div className="chat-box-footer">
                {!!hashUsuario ? (
                  <Input
                    type="textarea"
                    placeholder="Digite aqui..."
                    onChange={onChangeMensagem}
                    onKeyUp={onKeyUp}
                    value={mensagem}
                    fluid={true}
                    action={true}
                    autoFocus={true}
                  >
                    <input />
                    <Button positive={true} icon="send" onClick={sendMessage} content="Enviar" />
                    <Popup
                      content="Alterar meu nome"
                      trigger={
                        <Button className="btn-ui-1" icon="exchange" onClick={openModal} />
                      }
                    />
                  </Input>
                ) : (
                  <div className="box-interagir">
                    <Button className="btn-ui-1" onClick={openModal}>Interagir no Chat</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default QuestionChat;
